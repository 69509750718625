import {Component, Input} from '@angular/core';
import {Project} from "../../api/model/project";
import {Alert, AlertLevel} from "../../api/model/alert";

@Component({
  selector: 'app-alert-icon',
  templateUrl: './alert-icon.component.html',
  styleUrls: ['./alert-icon.component.sass']
})
export class AlertIconComponent {
  @Input() project!: Project;
  @Input() alerts!: Alert[];

  hasCritical(): boolean {
    return this.criticalAlerts().length > 0;
  }

  hasWarning(): boolean {
    return this.warningAlerts().length > 0;
  }

  criticalTooltip(): string {
    return this.criticalAlerts().map(a => `${a.message} (${a.culpritEquipmentIds.length})`).join("\n");
  }

  warningTooltip(): string {
    return this.warningAlerts().map(a => `${a.message} (${a.culpritEquipmentIds.length})`).join("\n");
  }

  private criticalAlerts(): Alert[] {
    return this.alerts.filter(a => a.project.id == this.project.id && a.level == AlertLevel.CRITICAL);
  }

  private warningAlerts(): Alert[] {
    return this.alerts.filter(a => a.project.id == this.project.id && a.level == AlertLevel.WARNING);
  }
}
