<h1 mat-dialog-title>
  <ng-container *ngIf="isAssign()">Assign&nbsp;</ng-container>
  <ng-container *ngIf="isMove()">Moving&nbsp;</ng-container>
  <ng-container *ngIf="isCopy()">Copying&nbsp;</ng-container>
  <strong>{{ data.equipment.length }}</strong> items to another project
</h1>

<div class="dense-1" mat-dialog-content>
  <h5>Copying only copies these fields: Manufacturer, SKU, Model, Quantity</h5>

  <app-equipment-condense-table [equipment]="data.equipment"></app-equipment-condense-table>
  <form (ngSubmit)="onSubmit()" [formGroup]="moveToForm" class="add-edit-equipment-form">

    <mat-autocomplete #catalogAuto="matAutocomplete" (optionSelected)="projectAutocompleteSelect($event)"
                      class="equipment-catalog-autocomplete" panelWidth="440px">
      <mat-option *ngFor="let p of filteredProjects" [id]="p.id" [value]="p">
        <span [style.padding-left]="p.hierarchyLevel * 20 + 'px'" class="project-option">
          <app-project-status [condense]="true" [project]="p"></app-project-status>
          <span>{{ isAssign() ? p.nameWithoutParents : p.name }}</span>
        </span>
      </mat-option>
    </mat-autocomplete>

    <div>
      <mat-form-field class="project">
        <mat-label>Project</mat-label>
        <input #filter [matAutocomplete]="catalogAuto" formControlName="projectId" matInput placeholder="Ex. 0263"
               type="text">
        <!--   <mat-spinner *ngIf="filterLoading" class="progress-spinner" diameter="24" matSuffix></mat-spinner>
           <button (click)="clearFilter()" aria-label="Clear" mat-icon-button matSuffix>
             <mat-icon *ngIf="!filterLoading"
                       [class.empty]="isFilterEmpty()">close
             </mat-icon>
           </button>-->
        <mat-error *ngIf="moveToForm.controls.projectId.hasError('projectAutocompleted')">
          You must select a project from autocomplete dropdown.
        </mat-error>
      </mat-form-field>
    </div>

  </form>
</div>
<div mat-dialog-actions>
  <button (click)="onCancelClick()" [color]="moveToForm.dirty ? 'warn' : ''"
          mat-button>Cancel
  </button>
  <button (click)="onPrimaryClick()" [disabled]="!moveToForm.valid" color="primary" mat-raised-button>
    <ng-container *ngIf="isAssign()">Assign</ng-container>
    <ng-container *ngIf="isMove()">Move</ng-container>
    <ng-container *ngIf="isCopy()">Copy</ng-container>
  </button>
  <mat-error *ngIf="apiError" class="api-error">
    {{ apiError }}
  </mat-error>
</div>
