import {Authored} from "./common";
import {Equipment} from "./equipment";

export interface Rma {
  id: string;
  created: Authored;
  updated: Authored;

  rmaNumber: string;
  serialNumber: string;
  manufacturer: string;
  sku: string;
  model: string;
  description: string;
  hyperlink: string;
  clientName: string;

  firmwareVersion: string | null;
  projectId: string | null;
  invoiceNumber: string | null;
  invoiceDate: Date | null;
  installedDate: Date | null;
  statuses: RmaStatus[];


  comments: Comment[];
  spareParts: Equipment[];
  loanedItems: LoanedItem[];

  warrantyUntil: Date | null;
  totalTimeSpentInHours: number;
  related: Rma[];
}

export interface LoanedItem {
  serialNumber: string;
  manufacturer: string;
  sku: string;
  model: string;
  dispatchDate: Date | null;
  returnedDate: Date | null;
}

export interface RmaStatus {
  date: Date;
  type: RmaStatusType;
  description: string;
  timeSpentInHours: number | null;
  timeSpentInMinutes: number | null;
}

export enum RmaStatusType {
  RECEIVED = "RECEIVED",
  FIRST_INSPECTION = "FIRST_INSPECTION",
  SUPPLIER_CONTACTED = "SUPPLIER_CONTACTED",
  SENT_TO_SUPPLIER = "SENT_TO_SUPPLIER",
  RECEIVED_FROM_SUPPLIER = "RECEIVED_FROM_SUPPLIER",
  SERVICE = "SERVICE",
  TESTING = "TESTING",
  MAINTENANCE = "MAINTENANCE",
  READY_TO_SHIP = "READY_TO_SHIP",
  ON_CLIENT_PREMISES = "ON_CLIENT_PREMISES",
}
