import {Component} from '@angular/core';
import {Activity} from "../../api/model/activity";

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.sass']
})
export class ActivityComponent {
  latest: Activity[] = [];
}
