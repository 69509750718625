<mat-toolbar class="toolbar" color="primary">
  <mat-toolbar-row>
    <a class="title" href="/">
      <img alt="Sonus Space" class="logo-bw" src="assets/logo-black-white-64x64.png" title="Sonus Space"/>
      <img alt="Sonus Space" class="logo-colored" src="assets/logo-colored-64x64.png" title="Sonus Space"/>
    </a>
    <ng-template [ngIf]="isLoggedIn">
      <span class="spacer"></span>
      <button (click)="showSearch()" *ngIf="!searchVisible" aria-label="Global Search" class="search-button"
              mat-icon-button>
        <mat-icon>search</mat-icon>
      </button>
      <app-global-search [class.search-visible]="searchVisible" class="search-field"></app-global-search>
      <button (click)="loadAlerts()" [matMenuTriggerFor]="menu" aria-label="Notifications" mat-icon-button>
        <mat-icon [matBadge]="alertBadge()" matBadgeColor="warn">notifications</mat-icon>
      </button>
      <mat-menu #menu="matMenu" class="alerts" xPosition="before">
        <app-alert-menu-list [alerts]="alerts"></app-alert-menu-list>
        <span *ngIf="!alerts || alerts.length == 0" mat-menu-item>
        <mat-icon color="primary">sentiment_very_satisfied</mat-icon>
        <span>Yay! Nothing requires immediate attention from you right now.</span>
      </span>
      </mat-menu>
      <span class="small-spacer"></span>
      <a class="current-user-name" matTooltip="Go to User Profile"
         routerLink="/settings/user-profile">{{ currentUser()?.name }}</a>
      <button [matMenuTriggerFor]="settings" aria-label="Settings" class="settings-button" mat-icon-button>
        <mat-icon>settings</mat-icon>
      </button>
      <mat-menu #settings="matMenu" class="alerts" xPosition="before">
        <a mat-menu-item routerLink="/settings/equipment-catalog">
          <mat-icon>view_list</mat-icon>
          <span>Equipment Catalog</span>
        </a>
        <a mat-menu-item routerLink="/settings/spare-parts-catalog">
          <mat-icon>view_list</mat-icon>
          <span>Service Spare Parts Catalog</span>
        </a>
        <a mat-menu-item routerLink="/settings/manufacturer-responsibility">
          <mat-icon>support_agent</mat-icon>
          <span>Manufacturer Responsibility</span>
        </a>
        <a mat-menu-item routerLink="/settings/project-import">
          <mat-icon>publish</mat-icon>
          <span>Project Import</span>
        </a>
        <a mat-menu-item routerLink="/settings/users">
          <mat-icon>manage_accounts</mat-icon>
          <span>Users</span>
        </a>
        <button (click)="logout()" aria-label="Logout" mat-menu-item>
          <mat-icon>logout</mat-icon>
          <span>Logout</span>
        </button>
      </mat-menu>
    </ng-template>
  </mat-toolbar-row>
</mat-toolbar>

<nav *ngIf="isLoggedIn" [tabPanel]="tabPanel" mat-tab-nav-bar>
  @for (link of links; track link.path) {
    <a #rla="routerLinkActive" [active]="rla.isActive" mat-tab-link [routerLink]="link.path"
       routerLinkActive="mdc-tab-indicator--active">{{ link.label }}</a>
  }
</nav>
<mat-tab-nav-panel #tabPanel class="grid-container">
  <router-outlet></router-outlet>
</mat-tab-nav-panel>

<!--<div class="sticky-breadcrumbs">
  <button aria-label="Menu" class="menu" mat-icon-button>
    <mat-icon>menu</mat-icon>
  </button>
  <app-breadcrumb class="big-spacer"></app-breadcrumb>
</div>-->

<div class="version">ver {{ buildNumber }}
  <span *ngIf="!(buildIsUpToDate$ | async)" class="new">, new {{ buildNumberNew$ | async }}</span>
</div>
