<h1 mat-dialog-title>
  {{ verb }} project {{ data.project?.name }}
</h1>
<div mat-dialog-content>
  <form (ngSubmit)="onSubmit()" [formGroup]="addEditForm" class="add-edit-project-form">
    <div class="name-and-accounting-id">
      <mat-form-field class="accounting-id">
        <mat-label>Accounting Id</mat-label>
        <input formControlName="accountingId" matInput placeholder="Ex. 0263" type="text">
        <mat-error *ngIf="addEditForm.controls.accountingId.hasError('required')">
          field <strong>required</strong>
        </mat-error>
      </mat-form-field>
      <mat-form-field class="name">
        <mat-label>Name</mat-label>
        <input formControlName="name" matInput placeholder="Ex. Lėlė I Etapas" type="text">
        <mat-error *ngIf="addEditForm.controls.name.hasError('required')">
          field <strong>required</strong>
        </mat-error>
      </mat-form-field>
      <button (click)="toggleNameLock()" *ngIf="data.project" mat-icon-button type="button">
        <mat-icon *ngIf="!nameLock">lock_open</mat-icon>
        <mat-icon *ngIf="nameLock">lock</mat-icon>
      </button>

      <mat-form-field *ngIf="data.project?.id" appearance="fill">
        <mat-label>Status</mat-label>
        <mat-select formControlName="status">
          <mat-option *ngFor="let s of statuses" [value]="s">{{ s }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div *ngIf="data.project?.id" class="details">
      <div class="column">
        <div class="dates">
          <mat-form-field appearance="fill" class="deadline date">
            <mat-label>Deadline</mat-label>
            <input
              #deadline="bsDatepicker"
              bsDatepicker
              formControlName="deadline"
              matInput>
            <mat-datepicker-toggle (click)="deadline.toggle()"
                                   [class.mat-datepicker-toggle-active]="deadline.isOpen"
                                   matIconSuffix></mat-datepicker-toggle>
          </mat-form-field>
          <mat-form-field appearance="fill" class="project-completed date">
            <mat-label>Completed</mat-label>
            <input
              #projectCompletedDate="bsDatepicker"
              bsDatepicker
              formControlName="projectCompletedDate"
              matInput>
            <mat-datepicker-toggle (click)="projectCompletedDate.toggle()"
                                   [class.mat-datepicker-toggle-active]="projectCompletedDate.isOpen"
                                   matIconSuffix></mat-datepicker-toggle>
          </mat-form-field>
        </div>

        <div class="remaining-fields">
          <mat-form-field appearance="fill">
            <mat-label>Client language</mat-label>
            <mat-select formControlName="clientPreferredLanguage">
              <mat-option value=""></mat-option>
              <mat-option value="LT">Lithuanian</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Dropbox link</mat-label>
            <input formControlName="dropboxProjectLink" matInput>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Address</mat-label>
            <input formControlName="locationAddress" matInput>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Google maps URL</mat-label>
            <input formControlName="locationMapsUrl" matInput>
          </mat-form-field>
        </div>
      </div>
      <div class="column second">
        <mat-form-field appearance="fill" class="description">
          <mat-label>Description</mat-label>
          <textarea cdkAutosizeMaxRows="18" cdkAutosizeMinRows="1"
                    cdkTextareaAutosize
                    formControlName="description"
                    matInput></textarea>
        </mat-form-field>
      </div>
    </div>

  </form>
</div>
<div mat-dialog-actions>
  <button (click)="onCancelClick()" [color]="addEditForm.dirty ? 'warn' : ''"
          mat-button>
    Cancel
  </button>
  <button (click)="onPrimaryClick()" [disabled]="!addEditForm.valid" color="primary" mat-raised-button>
    {{ verb }}
  </button>
  <button (click)="onDeleteClick()" *ngIf="data.project?.id" class="delete" mat-button>Delete</button>
  <mat-error *ngIf="apiError" class="api-error">
    {{ apiError }}
  </mat-error>
</div>
