import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Comment} from "../../api/model/comment";
import {CommentService} from "../../api/comment.service";
import {BelongsTo} from "../../api/model/common";
import {NgModel} from "@angular/forms";

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.sass']
})
export class CommentsComponent implements OnInit, OnChanges {
  constructor(private commentService: CommentService) {}

  @Input() for?: string = undefined;
  @Input() forId!: string;

  newComment: string = "";
  comments: Comment[] | undefined;

  ngOnInit() {
    this.commentService.list(this?.for as unknown as BelongsTo, this.forId)
      .subscribe(response => this.comments = response)
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit()
  }

  addComment(newCommentInput: NgModel) {
    const comment = newCommentInput.value.trim()
    if (comment.length > 0) {
      this.commentService.create({
        message: comment,
        belongsTo: this.for!!,
        belongsToId: this.forId
      }).subscribe(
        value => {
          console.log("comment created", value);
          newCommentInput.control.reset();
          this.comments = [value, ...(this.comments || [])]
        }
      )
    }
  }
}
