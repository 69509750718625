import {Component} from '@angular/core';
import {Manufacturer} from "../../api/model/manufacturer";
import {ManufacturerService} from "../../api/manufacturer.service";
import {MatDialog} from "@angular/material/dialog";
import {
  ManufacturerEditDialogComponent,
  ManufacturerEditDialogData
} from "./manufacturer-edit-dialog/manufacturer-edit-dialog.component";

@Component({
  selector: 'app-manufacturer',
  templateUrl: './manufacturer.component.html',
  styleUrls: ['./manufacturer.component.sass']
})
export class ManufacturerComponent {
  displayedColumns: string[] = ['name', 'warrantyInMonths', 'responsible', 'actions'];
  manufacturers: Manufacturer[] = [];

  constructor(
    public dialog: MatDialog,
    private manufacturerService: ManufacturerService) {
    this.loadManufacturers()
  }

  edit(name: string) {
    let manufacturer = this.manufacturers.find(value => value.name == name);
    const dialogData: ManufacturerEditDialogData = {
      name: name,
      warrantyInMonths: manufacturer?.warrantyInMonths || null,
      responsible: manufacturer?.responsible || [],
    }
    const dialogRef = this.dialog.open(ManufacturerEditDialogComponent, {data: dialogData, width: "500px"});

    dialogRef.afterClosed().subscribe(result => {
      if (result)
        this.loadManufacturers()
    });
  }

  delete(manufacturer: Manufacturer) {
    if (confirm(`Are you sure you want to remove responsible people for manufacturer ${manufacturer.name}?`)) {
      this.manufacturerService.delete(manufacturer.id).subscribe(
        _ => this.loadManufacturers()
      )
    }

  }

  private loadManufacturers() {
    this.manufacturerService.listUnassigned().subscribe(unassigned => {
      const unassignedManufacturers = unassigned.map(value => {
        return {
          name: value
        } as Manufacturer;
      })
      this.manufacturerService.list().subscribe(
        response => this.manufacturers = unassignedManufacturers.concat(response.sort((a, b) => a.name.localeCompare(b.name)))
      );
    });
  }
}
