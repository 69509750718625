import {HttpClient, HttpHeaders} from "@angular/common/http";
import {BehaviorSubject, timer} from "rxjs";
import {Injectable} from "@angular/core";
import {MatSnackBar} from "@angular/material/snack-bar";
import {SwUpdate} from "@angular/service-worker";
import buildInfo from "../../assets/buildinfo.json";
import {environment} from "../../environments/environment";

@Injectable({providedIn: 'root'})
export class BuildDetailsService {

  buildIsUpToDateSubject = new BehaviorSubject<boolean>(true);
  buildNumberAtCompile = buildInfo.version;
  newBuildNumberDetected = new BehaviorSubject<String>("");

  constructor(private http: HttpClient, private snackBar: MatSnackBar, private swUpdate: SwUpdate) {
    this.pollVersion()
  }

  private pollVersion() {
    const pollInterval = environment.versionDetectionPollInSeconds * 1000;
    timer(5000, pollInterval).subscribe(() => {
      console.log("build versions: compile, new",
        this.buildNumberAtCompile,
        this.newBuildNumberDetected.getValue(),
      );
      const headers = new HttpHeaders({'Cache-Control': 'no-cache', 'ngsw-bypass': ''});
      this.http.get<BuildInfo>('assets/buildinfo.json', {headers: headers}).subscribe(response => {
        const newBuildNumber = response.version;
        if (this.buildIsUpToDateSubject.getValue() && this.buildNumberAtCompile !== newBuildNumber) {
          this.newBuildNumberDetected.next(newBuildNumber);
          console.log("new build version detected: atCompile", this.buildNumberAtCompile, "new", newBuildNumber);
          const showUpdatePrompt = (value: boolean) => {
            this.buildIsUpToDateSubject.next(false);
            console.log("swUpdate checkForUpdate complete", value)
            this.snackBar.open("New app version available!", "Refresh")
              .onAction().subscribe(value => location.reload())
          }
          this.swUpdate.isEnabled && this.swUpdate.checkForUpdate().then(showUpdatePrompt) || showUpdatePrompt(true)
        }
      });
    });
  }
}

export interface BuildInfo {
  version: string,
}
