<div class="buttons-container">
    <button (click)="openCreateDialog()"
            class="add"
            color="primary"
            mat-raised-button>New RMA
    </button>
</div>

<div class="rma-list mat-elevation-z8">
    <table [dataSource]="dataSource" mat-table>

        <ng-container matColumnDef="rmaNumber">
            <th *matHeaderCellDef mat-header-cell>RMA Number</th>
            <td *matCellDef="let element" mat-cell>
                <div class="flex-row">
                    <a [routerLink]="element.rmaNumber">{{element.rmaNumber}}</a>
                    <mat-icon *ngIf="element.loanedItems.length > 0" [matTooltip]="loanedItemTooltip(element)"
                              class="small-icon">
                        sync_alt
                    </mat-icon>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="serialNumber">
            <th *matHeaderCellDef mat-header-cell>Serial Number</th>
            <td *matCellDef="let element" mat-cell> {{element.serialNumber}} </td>
        </ng-container>
        <ng-container matColumnDef="manufacturer">
            <th *matHeaderCellDef mat-header-cell>Manufacturer</th>
            <td *matCellDef="let element" mat-cell> {{element.manufacturer}} </td>
        </ng-container>
        <ng-container matColumnDef="sku">
            <th *matHeaderCellDef mat-header-cell>SKU</th>
            <td *matCellDef="let element" mat-cell> {{element.sku}} </td>
        </ng-container>
        <ng-container matColumnDef="model">
            <th *matHeaderCellDef mat-header-cell>Model</th>
            <td *matCellDef="let element" mat-cell> {{element.model}} </td>
        </ng-container>
        <ng-container matColumnDef="description">
            <th *matHeaderCellDef mat-header-cell>Problem Description</th>
            <td *matCellDef="let element" mat-cell> {{element.description}} </td>
        </ng-container>
        <ng-container matColumnDef="clientName">
            <th *matHeaderCellDef mat-header-cell>Client Name</th>
            <td *matCellDef="let element" mat-cell> {{element.clientName}} </td>
        </ng-container>
        <ng-container matColumnDef="lastStatus">
            <th *matHeaderCellDef mat-header-cell>Last Status</th>
            <td *matCellDef="let element" mat-cell>
                <app-rma-status [status]="lastStatus(element)"></app-rma-status>
            </td>
        </ng-container>
        <ng-container matColumnDef="timeSpent">
            <th *matHeaderCellDef mat-header-cell>Hours Spent</th>
            <td *matCellDef="let element" mat-cell>
                {{element.totalTimeSpentInHours}}
            </td>
        </ng-container>

        <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
        <tr (click)="jumpTo(element)" *matRowDef="let element; columns: displayedColumns"
            class="rma-row" mat-row></tr>
        <tr *matNoDataRow class="mat-row">
            <td class="mat-cell no-rma" colspan="4">
                <p>No RMAs found</p>
            </td>
        </tr>

    </table>
    <div *ngIf="rmas?.length === 0">
        No RMAs found.
    </div>
</div>

