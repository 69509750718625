import {Injectable} from '@angular/core';
import {BehaviorSubject, filter} from "rxjs";
import {ActivatedRouteSnapshot, Data, NavigationEnd, Router} from "@angular/router";
import {Project} from '../api/model/project';
import {projectUrl} from "../project/url.pipe";

/**
 * Based on a clean example https://github.com/ocanzillon/angular-breadcrumb
 */
@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  private readonly _breadcrumbs$ = new BehaviorSubject<Breadcrumb[]>([]);

  // Observable exposing the breadcrumb hierarchy
  readonly breadcrumbs$ = this._breadcrumbs$.asObservable();

  constructor(private router: Router) {
    this.router.events.pipe(
      // Filter the NavigationEnd events as the breadcrumb is updated only when the route reaches its end
      filter((event) => event instanceof NavigationEnd)
    ).subscribe(event => {
      // Construct the breadcrumb hierarchy
      const root = this.router.routerState.snapshot.root;
      const breadcrumbs: Breadcrumb[] = [];
      this.addBreadcrumb(root, [], breadcrumbs);

      // Emit the new hierarchy
      this._breadcrumbs$.next(breadcrumbs);
    });

  }

  private addBreadcrumb(route: ActivatedRouteSnapshot | null, parentUrl: string[], breadcrumbs: Breadcrumb[]) {
    if (route) {
      // Construct the route URL
      const routeUrl = parentUrl.concat(route.url.map(url => url.path));

      // Add an element for the current route part
      const breadcrumbData = this.getBreadcrumbData(route.data);
      if (breadcrumbData && breadcrumbData.hasOwnProperty("project")) {
        const project = breadcrumbData["project"] as Project;
        const projectBreadcrumbs: Breadcrumb[] = [];
        projectBreadcrumbs.push({
          label: project.nameWithoutParents,
          url: projectUrl(project),
        })
        this.addParent(project.parentId, project.parents, projectBreadcrumbs);
        projectBreadcrumbs.reverse();
        breadcrumbs.push(...projectBreadcrumbs);
      } else if (breadcrumbData) {
        const breadcrumb = {
          label: breadcrumbData,
          url: '/' + routeUrl.join('/')
        };
        breadcrumbs.push(breadcrumb);
      }
      console.log("BreadcrumbService addBreadcrumb", route, breadcrumbData)

      // Add another element for the next route part
      this.addBreadcrumb(route.firstChild, routeUrl, breadcrumbs);
    }
  }

  private addParent(parentId: string | undefined, parents: Project[], breadcrumbs: Breadcrumb[]) {
    if (parentId !== undefined) {
      const parent = parents && parents.find(p => p.id == parentId)
      if (parent !== undefined) {
        breadcrumbs.push({label: parent.nameWithoutParents, url: projectUrl(parent)})
        this.addParent(parent.parentId, parents, breadcrumbs);
      }
    }
  }

  private getBreadcrumbData(data: Data): any {
    // The breadcrumb can be defined as a static string or as a function to construct the breadcrumb element out of the route data
    return data['breadcrumb'] ?
      (typeof data['breadcrumb'] === 'function' ? data['breadcrumb'](data) : data['breadcrumb']) :
      undefined;
  }
}

export interface Breadcrumb {
  label: string;
  url: string;
}
