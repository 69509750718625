import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {
  BookmarkUpdateRequest,
  Project,
  ProjectCreateUpdateRequest,
  ProjectDetails,
  ProjectImport,
  ProjectNameUpdateRequest,
  ProjectPermissionUpdateRequest,
  ProjectSortingUpdateRequest,
  ProjectStatusUpdateRequest,
  ProjectType
} from "./model/project";
import {environment} from "../../environments/environment";
import {AuthService} from "./auth.service";

@Injectable()
export class ProjectService {
  constructor(
    private authService: AuthService,
    private http: HttpClient
  ) {
  }

  list(filter?: string, accountingId?: string, type?: ProjectType, status?: string[], orderBy?: string, orderDirection?: string): Observable<Project[]> {
    let params = new HttpParams({
      fromObject: {status: status || []}
    });
    if (filter) params = params.append("filter", filter);
    if (accountingId) params = params.append("accountingId", accountingId);
    if (type) params = params.append("type", type);
    if (orderBy) params = params.append("orderBy", orderBy);
    if (orderDirection) params = params.append("orderDirection", orderDirection);
    return this.http.get<Project[]>(environment.apiUrl + "/project", {params})
  }

  create(request: ProjectCreateUpdateRequest): Observable<Project> {
    return this.http.post<Project>(environment.apiUrl + "/project", request)
  }

  get(id: string): Observable<Project> {
    return this.http.get<Project>(environment.apiUrl + "/project/" + id)
  }

  delete(id: string): Observable<any> {
    return this.http.delete(environment.apiUrl + "/project/" + id)
  }

  updateName(id: string, request: ProjectNameUpdateRequest): Observable<Project> {
    return this.http.put<Project>(environment.apiUrl + "/project/" + id + "/name", request)
  }

  updateStatus(id: string, request: ProjectStatusUpdateRequest): Observable<Project> {
    return this.http.put<Project>(environment.apiUrl + "/project/" + id + "/status", request)
  }

  updateDetails(id: string, request: ProjectDetails): Observable<Project> {
    return this.http.put<Project>(environment.apiUrl + "/project/" + id + "/details", request)
  }

  updatePermissions(id: string, request: ProjectPermissionUpdateRequest): Observable<Project> {
    return this.http.put<Project>(environment.apiUrl + "/project/" + id + "/permissions", request)
  }

  updateParent(id: string, parentId: string | null): Observable<Project> {
    return this.http.put<Project>(`${environment.apiUrl}/project/${id}/parent?parentId=${parentId}`, null)
  }

  updateLock(id: string, lock: boolean): Observable<Project> {
    return this.http.put<Project>(`${environment.apiUrl}/project/${id}/lock?lock=${lock}`, null)
  }

  updateSorting(request: ProjectSortingUpdateRequest): Observable<any> {
    return this.http.put(`${environment.apiUrl}/project/sorting`, request)
  }

  listBookmarked(): Observable<Project[]> {
    return this.http.get<Project[]>(environment.apiUrl + "/project/bookmark");
  }

  setBookmark(id: string, bookmark: boolean): Observable<any> {
    const request: BookmarkUpdateRequest = {
      bookmark: bookmark
    }

    this.authService.getCurrentUser().subscribe(user => {
      const bookmarked = user!.bookmarkedProjects
      if (bookmark && bookmarked.indexOf(id) < 0) {
        bookmarked.push(id);
      } else if (!bookmark && bookmarked.indexOf(id) >= 0) {
        bookmarked.splice(bookmarked.indexOf(id), 1);
      }
    });

    return this.http.put<any>(environment.apiUrl + "/project/" + id + "/bookmark", request)
  }

  import(request: ProjectImport[]): Observable<Project[]> {
    return this.http.post<any>(environment.apiUrl + "/project/import", request);
  }
}
