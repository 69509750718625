import {MomentDateAdapter} from "@angular/material-moment-adapter";
import {HttpErrorResponse} from "@angular/common/http";
import {ApiError} from "./api/model/common";
import {AbstractControl} from "@angular/forms";
import moment from "moment";
import {Project} from "./api/model/project";

export function transformToProjectsWithChildren(projects: Project[]): Project[] {
  return projects.filter(p => !p.parentId)
    .map(p => populateChildren(p, projects));
}

export function populateChildren(currentNode: Project, allProjects: Project[] | undefined, level: number = 0): Project {
  currentNode.hierarchyLevel = !currentNode.hierarchyLevel ? level : currentNode.hierarchyLevel;
  currentNode.children = allProjects?.filter(p => p.parentId === currentNode.id)
    .map(child => populateChildren(child, allProjects, currentNode.hierarchyLevel + 1))
    .sort((a, b) => a.sort - b.sort) || [];
  return currentNode;
}

export class MyDateAdapter extends MomentDateAdapter {
  override getFirstDayOfWeek(): number {
    return 1
  }
}

export function apiErrorToMessage(error: any): string {
  return ((error as HttpErrorResponse)?.error as ApiError)?.message || (error as HttpErrorResponse)?.message || error;
}

export function datepickerDefaultToFriday(formControl: AbstractControl<any, any> | null, value: Date) {
  console.log("utils datepickerDefaultToFriday", formControl, value);
  if (value && value.getHours() == 0 && value.getMinutes() == 0 && value.getSeconds() == 0) {
    // set to friday as it's desired behavior
    // set to random minute, so it doesn't get triggered second time when using datepicker
    formControl?.setValue(moment(value).set({weekday: 5, minute: 42}).toDate());
  }
}
