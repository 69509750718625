import {Component} from '@angular/core';
import {RmaService} from "../../api/rma.service";
import {Rma, RmaStatus} from "../../api/model/rma";
import {MatDialog} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {CreateRmaDialogComponent} from "../create-rma-dialog/create-rma-dialog.component";
import {MatTableDataSource} from "@angular/material/table";

@Component({
    selector: 'app-rma-list',
    templateUrl: './rma-list.component.html',
    styleUrls: ['./rma-list.component.sass']
})
export class RmaListComponent {
    rmas: Rma[] | undefined;

    dataSource = new MatTableDataSource<Rma>();
    displayedColumns: string[] = ["rmaNumber", "lastStatus", "clientName", "manufacturer", "sku", "model", "description", "serialNumber", "timeSpent"];

    constructor(
        public dialog: MatDialog,
        private rmaService: RmaService,
        private router: Router,
    ) {
        rmaService.list().subscribe(rmas => {
            this.rmas = rmas.sort((a, b) => b.rmaNumber.localeCompare(a.rmaNumber));
            this.dataSource.data = this.rmas;
        });
    }

    openCreateDialog(): void {
        const dialogRef = this.dialog.open(CreateRmaDialogComponent, {
            data: {},
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                const rma = result as Rma;
                this.router.navigate(["/service", rma.rmaNumber]);
            }
        });
    }

    jumpTo(rma: Rma) {
        this.router.navigate(["/service", rma.rmaNumber]);
    }

    lastStatus(rma: Rma): RmaStatus | undefined {
        return rma.statuses.at(-1);
    }

    loanedItemTooltip(rma: Rma): string {
        return `Substitute${rma.loanedItems.length > 1 ? 's were' : ' was'} loaned`;
    }
}
