<table [dataSource]="manufacturers" class="manufacturer-table mat-elevation-z8" mat-table>

  <ng-container matColumnDef="name">
    <th *matHeaderCellDef mat-header-cell>Manufacturer</th>
    <td *matCellDef="let element" mat-cell> {{element.name}} </td>
  </ng-container>

  <ng-container matColumnDef="warrantyInMonths">
    <th *matHeaderCellDef mat-header-cell>Warranty in Months</th>
    <td *matCellDef="let element" mat-cell> {{element.warrantyInMonths}} </td>
  </ng-container>

  <ng-container matColumnDef="responsible">
    <th *matHeaderCellDef mat-header-cell>Responsible people</th>
    <td *matCellDef="let element" mat-cell>
      <span *ngIf="!element.responsible || element.responsible.length == 0">
        <mat-icon class="small-icon" color="warn">warning</mat-icon>
        No one yet!
      </span>
      <span *ngFor="let r of element.responsible; let last = last">
        <app-user [email]="r"></app-user><span *ngIf="!last">, </span>
      </span>
    </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th *matHeaderCellDef mat-header-cell></th>
    <td *matCellDef="let element" mat-cell>
      <button (click)="edit(element.name)" class="small-icon-button" mat-icon-button>
        <mat-icon class="small-icon" matPrefix>edit</mat-icon>
      </button>
      <button (click)="delete(element)" class="small-icon-button" mat-icon-button>
        <mat-icon class="small-icon" matPrefix>delete</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
  <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
</table>
