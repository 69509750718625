import {Pipe, PipeTransform} from '@angular/core';
import _ from "lodash";

export function makeReadable(original: String) {
  return _.startCase(_.toLower(original.replaceAll("_", " ")))
    .replaceAll("Pdi", "PDI");
}

@Pipe({
  name: 'readable'
})
export class ReadablePipe implements PipeTransform {
  transform(original: String): string {
    return makeReadable(original);
  }
}
