import {Component, Input, ViewChild} from '@angular/core';
import {MatChipEditedEvent} from "@angular/material/chips";
import {FormControl, FormGroup} from "@angular/forms";
import {MatMenuTrigger} from "@angular/material/menu";

export interface Tag {
  name: string;
}

@Component({
  selector: 'app-project-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.sass']
})
export class TagsComponent {
  @Input() tags!: Tag[];

  @ViewChild(MatMenuTrigger) newTagTrigger!: MatMenuTrigger;
  showNewTagInput: boolean = false;

  newTagForm = new FormGroup({
    name: new FormControl('', [
      // Validators.required, Validators.minLength(3)
    ]),
  });

  onSubmit(): void {
    const value = (this.newTagForm.value["name"] || "").trim();
    if (value) {
      this.tags.push({name: value});
    }
    this.newTagForm.reset();
    this.showNewTagInput = false;
  }

  remove(tag: Tag): void {
    const index = this.tags.indexOf(tag);

    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }

  edit(tag: Tag, event: MatChipEditedEvent) {
    const value = event.value.trim();

    // Remove tag if it no longer has a name
    if (!value) {
      this.remove(tag);
      return;
    }

    // Edit existing tag
    const index = this.tags.indexOf(tag);
    if (index > 0) {
      this.tags[index].name = value;
    }
  }
}
