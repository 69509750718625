import {Component, HostListener, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ProjectService} from "../../api/project.service";
import {concat, EMPTY, Observable} from "rxjs";
import {Project, ProjectDetails, ProjectStatus} from "../../api/model/project";
import {ComponentCanDeactivate} from "../../pending-changes.guard";
import {apiErrorToMessage} from "../../util";

@Component({
  selector: 'app-project-add-edit-dialog',
  templateUrl: './project-add-edit-dialog.component.html',
  styleUrls: ['./project-add-edit-dialog.component.sass']
})
export class ProjectAddEditDialogComponent implements ComponentCanDeactivate {
  statuses: string[] = Object.values(ProjectStatus);
  verb = this.data.project?.id ? "Save" : "Create";
  nameLock: boolean = !!this.data.project?.id;
  addEditForm = new FormGroup({
    accountingId: new FormControl(this.data.project?.accountingId, [Validators.required]),
    name: new FormControl(this.data.project?.nameWithoutParents, [Validators.required]),
    status: new FormControl(this.data.project?.status),

    deadline: new FormControl(this.data.project?.details?.deadline),
    projectCompletedDate: new FormControl(this.data.project?.details?.projectCompletedDate),
    clientPreferredLanguage: new FormControl(this.data.project?.details?.clientPreferredLanguage),
    locationAddress: new FormControl(this.data.project?.details?.location.address),
    locationMapsUrl: new FormControl(this.data.project?.details?.location.mapsUrl),
    description: new FormControl(this.data.project?.details?.description?.replaceAll("<br />", "")),
    dropboxProjectLink: new FormControl(this.data.project?.details?.dropboxProjectLink),
  })
  apiError: string | undefined;

  constructor(
    private projectService: ProjectService,
    public dialogRef: MatDialogRef<ProjectAddEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ProjectAddEditDialogData,
  ) {
    dialogRef.disableClose = true;
    if (this.data.project?.accountingId) {
      this.addEditForm.controls.accountingId.disable()
    }
    if (this.data.project?.id) {
      this.addEditForm.controls.name.disable()
    }
  }

  @HostListener('window:keyup.esc') onKeyUp() {
    this.dialogRef.close();
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return !this.addEditForm.dirty;
  }

  onCancelClick() {
    this.dialogRef.close();
  }

  onPrimaryClick() {
    this.onSubmit()
  }

  onSubmit() {
    this.apiError = undefined;

    concat(
      this.updateNameAndAccountingId(),
      this.updateDetails(),
      this.updateStatus(),
    ).subscribe({
      next: (project) => {
        console.log("dialog updated project once", project);
        this.data.project = project;
      },
      error: error => {
        console.log("dialog failed to add/edit", error)
        this.apiError = apiErrorToMessage(error);
        return EMPTY
      },
      complete: () => {
        if (!this.apiError) {
          this.dialogRef.close(this.data.project || true)
        }
      }
    });

    /*.subscribe({
      next: project => {
        console.log("dialog edited project details", project)
        this.dialogRef.close(project)
      },
      error: error => {
        console.log("dialog failed editing project details", error)
        this.apiError = (error as HttpErrorResponse).message;
        return EMPTY
      }
    });*/
  }

  toggleNameLock() {
    this.nameLock = !this.nameLock
    if (this.nameLock) {
      this.addEditForm.controls.name.disable()
      this.addEditForm.controls.name.setValue(this.data.project?.nameWithoutParents)
      this.addEditForm.controls.accountingId.setValue(this.data.project?.accountingId)
    } else {
      this.addEditForm.controls.name.enable()
    }
  }

  onDeleteClick() {
    if (prompt("Are you sure you want to delete? Action is irreversible. Type 'yes' to continue") == 'yes') {
      const num1 = this.getRandomInt(10, 70)
      const num2 = this.getRandomInt(10, 30)
      let lastCheck = prompt(`You sure? Action is IRREVERSIBLE. Type answer to delete: ${num1} + ${num2} = ?`);
      let answer = num1 + num2;
      if (parseInt(lastCheck || "") == answer) {
        this.projectService.delete(this.data.project!.id).subscribe({
          next: value => {
            console.log("project deleted", this.data.project!.id, value);
            this.dialogRef.close(true);
          },
          error: error => {
            this.apiError = apiErrorToMessage(error);
          }
        })
      } else {
        alert(`That wasn't the correct answer, ${lastCheck} instead of ${answer}... phew. If you still want to delete try again.`);
      }
    } else {
      alert("That wasn't a 'yes'... phew.");
    }
  }

  private getRandomInt(min: number, max: number): number {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
  }

  private updateNameAndAccountingId(): Observable<Project | never> {
    if (!this.nameLock && this.addEditForm.value.accountingId != "" && this.addEditForm.value.name != "") {
      const request = {
        accountingId: this.addEditForm.value.accountingId || this.data.project?.accountingId || "",
        name: this.addEditForm.value.name || "",
        parentId: undefined,
      };
      return (this.data.project?.id ?
          this.projectService.updateName(this.data.project?.id, request)
          : this.projectService.create(request)
      );
    }
    return EMPTY;
  }

  private updateDetails(): Observable<Project | never> {
    if (this.data.project?.id) {
      const formDetails = this.convertFormToProjectDetails();
      if (!this.compareDetails(formDetails, this.data.project?.details)) {
        return this.projectService.updateDetails(this.data.project?.id, formDetails);
      }
    }
    return EMPTY;
  }

  private updateStatus(): Observable<Project | never> {
    if (this.data.project?.id && this.addEditForm.controls.status.dirty) {

      return this.projectService.updateStatus(this.data.project?.id, {
        status: this.addEditForm.value.status || ProjectStatus.PENDING
      });
    }
    return EMPTY;
  }

  private compareDetails(a: ProjectDetails, b: ProjectDetails | undefined) {
    return Object.entries(a)
      .map(([key, value]) =>
        key == "location" || (b as any)[key] == value
      )
      .filter(value => !value)
      .length == 0
  }

  private convertFormToProjectDetails(): ProjectDetails {
    return {
      deadline: this.addEditForm.controls.deadline.value?.valueOf() || undefined,
      clientPreferredLanguage: this.addEditForm.controls.clientPreferredLanguage.value || undefined,
      description: this.addEditForm.controls.description.value?.replaceAll("\n", "\n<br />") || undefined,
      location: {
        address: this.addEditForm.controls.locationAddress.value || undefined,
        mapsUrl: this.addEditForm.controls.locationMapsUrl.value || undefined,
      },
      dropboxProjectLink: this.addEditForm.controls.dropboxProjectLink.value || undefined,
      projectCompletedDate: this.addEditForm.controls.projectCompletedDate.value?.valueOf() || undefined,
    };
  }
}

export interface ProjectAddEditDialogData {
  project: Project | undefined;
}
