import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Project, ProjectStatus} from "../../api/model/project";
import {ProjectService} from "../../api/project.service";
import {
  ProjectAddEditDialogComponent,
  ProjectAddEditDialogData
} from "../add-edit-dialog/project-add-edit-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {AuthService} from "../../api/auth.service";

@Component({
  selector: 'app-project-main',
  templateUrl: './project-main.component.html',
  styleUrls: ['./project-main.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class ProjectMainComponent implements OnInit {
  project: Project | undefined;
  bookmarked: boolean = false;
  statuses: string[] = Object.values(ProjectStatus);
  activeTabIndex: number = 0;
  private projectId: string = "";

  constructor(public dialog: MatDialog,
              private projectService: ProjectService,
              private authService: AuthService,
              private router: Router,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.router.events.subscribe((res) => {
      this.activeTabIndex = this.router.url.indexOf("equipment") > 0 ? 1 : (this.router.url.indexOf("supply-timeline") > 0 ? 2 : 0)
    });
    this.route.data
      .subscribe(data => {
        console.log("project main data on init", data);
        this.project = data['project'];
        this.authService.getCurrentUser().subscribe(user => {
          this.bookmarked = user?.bookmarkedProjects?.indexOf(this.projectId)! >= 0 || false;
        });
      });
    /*this.route.paramMap
    this.route.paramMap
      .subscribe(paramMap => {
        console.log("tab paramMap", this.route);
          this.projectId = paramMap.get('projectId') || "";
          this.fetchProject()
          this.authService.getCurrentUser().subscribe(user => {
            this.bookmarked = user?.bookmarkedProjects?.indexOf(this.projectId)! >= 0 || false;
          });
        }
      )*/
  }

  fetchProject() {
    this.projectService.get(this.projectId)
      .subscribe(p => this.project = p)
  }

  editProjectName() {
    const dialogData: ProjectAddEditDialogData = {
      project: this.project,
    };
    const dialogRef = this.dialog.open(ProjectAddEditDialogComponent, {
      maxHeight: "90vh",
      maxWidth: "1000px",
      width: "100%",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result)
        this.fetchProject()
    });
  }

  changeStatus(status: string) {
    this.projectService.updateStatus(this.projectId, {
      status: status as ProjectStatus
    }).subscribe(updatedProject =>
      this.project = updatedProject
    )
    console.log("changeStatus", status)
  }

  toggleProjectBookmark() {
    this.bookmarked = !this.bookmarked;
    this.projectService.setBookmark(this.projectId, this.bookmarked)
      .subscribe(value => {
        console.log("bookmark updated: projectId, value", this.projectId, this.bookmarked);
      })
  }
}
