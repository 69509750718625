<h1 mat-dialog-title>
  Your User Profile
</h1>

<form (ngSubmit)="onSubmit()" *ngIf="user" [formGroup]="userProfileForm" class="registration-form">
  <p>Change your display name or password here.</p>

  <div>
    <mat-form-field class="email">
      <mat-label>Email</mat-label>
      <input [value]="user.email" disabled="true" matInput type="text">
    </mat-form-field>
  </div>

  <div>
    <mat-form-field class="name">
      <mat-label>Name</mat-label>
      <input formControlName="name" matInput placeholder="Ex. Jonas" type="text">
    </mat-form-field>
  </div>

  <div>
    <mat-form-field class="old-password">
      <mat-label>Old Password</mat-label>
      <input formControlName="oldPassword" matInput type="password">
    </mat-form-field>
  </div>

  <div>
    <mat-form-field class="new-password">
      <mat-label>New Password</mat-label>
      <input formControlName="newPassword" matInput type="password">
    </mat-form-field>
  </div>

  <button [disabled]="userProfileForm.pristine || userProfileForm.invalid" color="primary" mat-raised-button
          type="submit">Save changes
  </button>
</form>

<mat-error *ngIf="apiError" class="api-error">
  {{ apiError }}
</mat-error>
