import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {catchError, Observable, of} from "rxjs";
import {environment} from "../../environments/environment";
import {Health} from "./model/common";
import {map} from "rxjs/operators";

@Injectable()
export class HealthService {
  constructor(private http: HttpClient) {
  }

  isBackendUp(): Observable<boolean> {
    return this.http.get<Health>(environment.apiUrl + "/health")
      .pipe(
        map(health => health.status === "UP"),
        catchError((err, caught) => {
          console.log("backend health check failed", err, caught)
          return of(false)
        })
      )
  }
}
