<h2>Bulk Insert to {{catalogGroupTitle()}} Catalog</h2>

<p>
    Provide a comma separated values (CSV) file in the format provided in
    <a [download]="exampleFilename()" [href]="exampleCsvData">this example file</a>. Make sure to use semicolon
    character (<strong>;</strong>) as separator.
</p>

<div>
    <input #csvInput (change)="csvInputChange($event)"
           accept=".csv" hidden="hidden" onclick="this.value=null" type="file"/>
    <button (click)="csvInput.click()" color="primary" mat-flat-button>Choose CSV file</button>
</div>

<p *ngIf="apiError">
    <mat-error class="api-error">
        {{ apiError }}
    </mat-error>
</p>

<div *ngIf="imported">
    <h2>Successful import</h2>
    <h3>Source file</h3>
    <p>
        filename: {{imported.file.name}}<br/>
        size: {{imported.file.size}} bytes
    </p>
    <h3>Imported {{imported.equipmentCatalog.length}} items to equipment catalog</h3>
</div>

<h2>{{catalogGroupTitle()}} Catalog</h2>
<table [dataSource]="catalog" class="mat-elevation-z8" mat-table>

    <ng-container matColumnDef="manufacturer">
        <th *matHeaderCellDef mat-header-cell>Manufacturer</th>
        <td *matCellDef="let element" mat-cell> {{element.manufacturer}} </td>
    </ng-container>

    <ng-container matColumnDef="sku">
        <th *matHeaderCellDef mat-header-cell>SKU</th>
        <td *matCellDef="let element" mat-cell> {{element.sku}} </td>
    </ng-container>

    <ng-container matColumnDef="model">
        <th *matHeaderCellDef mat-header-cell>Model</th>
        <td *matCellDef="let element" mat-cell> {{element.model}} </td>
    </ng-container>

    <ng-container matColumnDef="description">
        <th *matHeaderCellDef mat-header-cell>Description</th>
        <td *matCellDef="let element" mat-cell> {{element.description}} </td>
    </ng-container>

    <ng-container matColumnDef="hyperlink">
        <th *matHeaderCellDef mat-header-cell>Hyperlink</th>
        <td *matCellDef="let element" mat-cell> {{element.hyperlink}} </td>
    </ng-container>

    <ng-container matColumnDef="delete">
        <th *matHeaderCellDef mat-header-cell></th>
        <td *matCellDef="let element" mat-cell>
            <a (click)="deleteItem(element)">
                <mat-icon class="small-icon" matPrefix>delete</mat-icon>
            </a>
        </td>
    </ng-container>

    <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
    <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>

    <tr *matNoDataRow class="mat-row">
        <td class="mat-cell no-rma" colspan="4">
            <p>Catalog empty, go ahead - fill me up!</p>
        </td>
    </tr>
</table>
