import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {LOCALE_ID} from "@angular/core";
import {DATE_PIPE_DEFAULT_OPTIONS} from "@angular/common";


platformBrowserDynamic().bootstrapModule(AppModule, {
  providers: [
    {provide: DATE_PIPE_DEFAULT_OPTIONS, useValue: {dateFormat: 'y-MM-dd HH:mm'}},
    {provide: LOCALE_ID, useValue: 'lt-LT'},
  ]
})
  .catch(err => console.error(err));
