import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Project} from "../../api/model/project";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-project-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class OverviewComponent implements OnInit {
  project!: Project;
  constructor(
    private route: ActivatedRoute,
  ) {}
  ngOnInit() {
    this.route.parent!.data
      .subscribe(data => {
        console.log("overview data on init", data);
        this.project = data['project'];
      });
  }

}
