<table class="equipment-condense-table">
  <thead>
  <tr>
    <th>PC</th>
    <th>Mfr.</th>
    <th>SKU</th>
    <th>Model</th>
    <th>Qty.</th>
    <th>Status</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let e of equipment">
    <td>{{ e.projectCode }}</td>
    <td>{{ e.manufacturer }}</td>
    <td>{{ e.sku }}</td>
    <td>{{ e.model }}</td>
    <td>{{ e.quantity }}</td>
    <td>{{ e.status }}</td>
  </tr>
  </tbody>
</table>
