<div class="task-wrapper">
  <section class="task-section" *ngFor="let t of tasks">
    <div class="task-item-section">
      <mat-checkbox class="task-margin"
                    [color]="t.complete ? 'primary' : 'warn'"
                    [checked]="t.complete"
                    [indeterminate]="someComplete(t)"
                    (change)="setAll(t, $event.checked)">
      </mat-checkbox>
      <textarea class="inline-input" [(ngModel)]="t.text"
                #taskEdit="ngModel"
                (change)="saveTask(t, taskEdit)"
                cdkTextareaAutosize></textarea>
      <button class="action small-icon-button" mat-icon-button
              (click)="deleteTask(t)">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
    <div class="task-list-section">
      <ul>
        <li *ngFor="let subtask of t.subtasks; index as i" class="task-item-section">
          <mat-checkbox [(ngModel)]="subtask.complete"
                        color="primary"
                        (ngModelChange)="updateAllComplete(t)">
          </mat-checkbox>
          <textarea class="inline-input" [(ngModel)]="subtask.text" #subtaskEdit="ngModel"
                    (change)="saveTask(t, subtaskEdit)"
                    cdkTextareaAutosize></textarea>
          <button class="action small-icon-button" mat-icon-button
                  (click)="deleteSubtask(t, i)">
            <mat-icon>delete</mat-icon>
          </button>
        </li>
        <li>
          <div class="add-task">
          <span class="add-sign">
            <mat-icon>add</mat-icon>
          </span>
            <textarea class="inline-input" #subtaskAdd placeholder="add new subtask"
                      cdkTextareaAutosize></textarea>
            <button class="action small-icon-button" mat-icon-button
                    (click)="addSubtask(t, subtaskAdd)">
              <mat-icon>done</mat-icon>
            </button>
          </div>
        </li>
      </ul>
    </div>
  </section>
  <div class="add-task">
    <span class="add-sign"><mat-icon>add</mat-icon></span>
    <textarea class="inline-input" type="text" [(ngModel)]="addTaskField"
              placeholder="add new task" #taskAdd="ngModel"
              cdkTextareaAutosize></textarea>
    <button class="action small-icon-button" mat-icon-button
            (click)="addTask(taskAdd)">
      <mat-icon>done</mat-icon>
    </button>
  </div>

  <ng-container *ngIf="tasks === undefined">
    Loading tasks...
    <!-- TODO prettify loading screens -->
  </ng-container>
</div>
