<div class="activity">
  Not implemented yet
</div>
<!--ng-container *ngFor="let a of latest; let last = last">

  <div class="activity">
    {{ a.project.id }} {{ a.project.name }}
    <a [href]="'mailto:' + a.author">{{ a.author }}</a>
    {{ a.timestamp | date:'y-MM-dd HH:mm' }}

    <ng-container [ngSwitch]="a.type">
      <span *ngSwitchCase="activityType.COMMENT">
        commented
        <div class="detailed">
          <pre class="new">{{ a.new }}</pre>
        </div>
      </span>
      <span *ngSwitchCase="activityType.CREATE_PROJECT">
        create the project
        <div class="detailed">
          <pre class="new">{{ a.new }}</pre>
        </div>
      </span>
      <span *ngSwitchCase="activityType.UPDATE_PROJECT">
        updated the project
        <div class="detailed">
          <pre class="new">{{ a.new }}</pre>
          <pre class="old">{{ a.old }}</pre>
        </div>
      </span>
      <span *ngSwitchCase="activityType.UPDATE_ITEMS">
        updated items
        <div class="detailed">
          <pre class="new">{{ a.new }}</pre>
          <pre *ngIf="a.old" class="old">{{ a.old }}</pre>
        </div>
      </span>
      <span *ngSwitchDefault>
        did something
      </span>
    </ng-container>

  </div>
  <mat-divider *ngIf="!last"></mat-divider>
</ng-container-->
