import {Component, HostListener, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {EMPTY} from "rxjs";
import {FormControl, Validators} from "@angular/forms";
import {HttpErrorResponse} from "@angular/common/http";
import {ManufacturerService} from "../../../api/manufacturer.service";
import {ManufacturerCreateUpdateRequest} from "../../../api/model/manufacturer";
import {AuthService} from "../../../api/auth.service";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-manufacturer-edit-dialog',
  templateUrl: './manufacturer-edit-dialog.component.html',
  styleUrls: ['./manufacturer-edit-dialog.component.sass']
})
export class ManufacturerEditDialogComponent {
  warrantyInMonthsCtrl = new FormControl<number | null>(null);
  responsibleCtrl = new FormControl<string[]>([], Validators.minLength(1));
  apiError: string | undefined;

  constructor(
    private manufacturerService: ManufacturerService,
    private authService: AuthService,
    public dialogRef: MatDialogRef<ManufacturerEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ManufacturerEditDialogData,
    private _snackBar: MatSnackBar,
  ) {
    dialogRef.disableClose = true;
    this.warrantyInMonthsCtrl.setValue(data.warrantyInMonths)
    this.responsibleCtrl.setValue(data.responsible.slice());
  }

  @HostListener('window:keyup.esc') onKeyUp() {
    this.dialogRef.close();
  }

  onCancelClick() {
    this.dialogRef.close();
  }

  onPrimaryClick() {
    this.onSubmit()
  }

  onSubmit() {
    this.apiError = undefined;
    if (this.responsibleCtrl.value && this.responsibleCtrl.value.length > 0) {
      const request = {
        name: this.data.name,
        warrantyInMonths: this.warrantyInMonthsCtrl.value,
        responsible: this.responsibleCtrl.value,
      } as ManufacturerCreateUpdateRequest;

      this.manufacturerService.update(request)
        .subscribe({
          next: (response) => {
            console.log("dialog saved manufacturer", response);
            this.dialogRef.close(response);
          },
          error: error => {
            console.log("dialog failed to save", error)
            this.apiError = (error as HttpErrorResponse).message;
            return EMPTY;
          }
        });
    }
  }

  isSubmitDisabled() {
    return (this.responsibleCtrl.pristine && this.warrantyInMonthsCtrl.pristine)
      || !this.responsibleCtrl.value || this.responsibleCtrl.value?.length === 0;
  }
}

export interface ManufacturerEditDialogData {
  name: string;
  warrantyInMonths: number | null;
  responsible: string[];
}
