<div class="single-rma-component">
  <form [formGroup]="editForm" class="edit-rma-form dense-1 flex-column">
    <div class="form-fields flex">

      <div class="column rma-info">
        <h2>RMA Info
          <app-rma-status [condense]="true" [status]="rma.statuses.at(-1)"></app-rma-status>
        </h2>
        <div>
          <mat-form-field class="rma-number">
            <mat-label>RMA Number</mat-label>
            <input formControlName="rmaNumber" matInput placeholder="Ex. SON-01544" type="text">
          </mat-form-field>
        </div>
        <div>
          <mat-form-field appearance="fill" class="warranty-until date">
            <mat-label>Warranty Until</mat-label>
            <input
              bsDatepicker
              formControlName="warrantyUntil"
              matInput>
            <mat-icon
              matSuffix
              matTooltip="Automatically populated from Sonus invoice date, installed date and Manufacutrer's warranty period">
              help
            </mat-icon>
          </mat-form-field>
        </div>

        <div>
          <mat-form-field class="manufacturer">
            <mat-label>Manufacturer</mat-label>
            <input formControlName="manufacturer" matInput placeholder="Ex. Meyer Sound" type="text">
            <mat-error *ngIf="editForm.controls['manufacturer'].hasError('required')">
              field <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field class="model">
            <mat-label>Model</mat-label>
            <input formControlName="model" matInput placeholder="Ex. ULTRA-X40" type="text">
            <mat-error *ngIf="editForm.controls['model'].hasError('required')">
              field <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field class="serial-number">
            <mat-label>Serial Number</mat-label>
            <input formControlName="serialNumber" matInput placeholder="Ex. 10001563324" type="text">
            <mat-error *ngIf="editForm.controls['serialNumber'].hasError('required')">
              field <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field class="sku">
            <mat-label>SKU</mat-label>
            <input formControlName="sku" matInput placeholder="Ex. 09.287.001.17" type="text">
            <mat-error *ngIf="editForm.controls['sku'].hasError('required')">
              field <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </div>

        <div>
          <mat-form-field class="client-name">
            <mat-label>Client Name</mat-label>
            <input formControlName="clientName" matInput placeholder="Ex. LNDT" type="text">
            <mat-error *ngIf="editForm.controls['clientName'].hasError('required')">
              field <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field appearance="fill" class="description">
            <mat-label>Problem Description</mat-label>
            <textarea cdkAutosizeMaxRows="18" cdkAutosizeMinRows="1"
                      cdkTextareaAutosize
                      formControlName="description"
                      matInput></textarea>
            <mat-error *ngIf="editForm.controls['description'].hasError('required')">
              field <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </div>

        <div>
          <mat-form-field class="hyperlink">
            <mat-label>Hyperlink</mat-label>
            <input formControlName="hyperlink" matInput placeholder="Ex. https://sonus.lt/ULTRA-X40"
                   type="text">
            <mat-hint *ngIf="this.editForm.value['hyperlink']">Open in
              <a [href]="this.editForm.value['hyperlink']" target="_blank">new tab</a>
            </mat-hint>
          </mat-form-field>
        </div>

        <div>
          <mat-form-field class="invoice-number">
            <mat-label>Sonus Invoice Number</mat-label>
            <input formControlName="invoiceNumber" matInput placeholder="Ex. SON-2156609" type="text">
          </mat-form-field>
        </div>

        <div>
          <mat-form-field class="invoice-date date">
            <mat-label>Sonus Invoice Date</mat-label>
            <input
              #invoiceDate="bsDatepicker"
              bsDatepicker
              formControlName="invoiceDate"
              matInput>
            <mat-datepicker-toggle (click)="invoiceDate.toggle()"
                                   [class.mat-datepicker-toggle-active]="invoiceDate.isOpen"
                                   matIconSuffix></mat-datepicker-toggle>
          </mat-form-field>
        </div>

        <div>
          <mat-form-field class="installed-date date">
            <mat-label>Installed Date</mat-label>
            <input
              #installedDate="bsDatepicker"
              bsDatepicker
              formControlName="installedDate"
              matInput>
            <mat-datepicker-toggle (click)="installedDate.toggle()"
                                   [class.mat-datepicker-toggle-active]="installedDate.isOpen"
                                   matIconSuffix></mat-datepicker-toggle>
          </mat-form-field>
        </div>
      </div>
      <mat-divider vertical="true"></mat-divider>

      <div class="column statuses">
        <h3 *ngIf="rma.projectId && relatedProject">Related Project:
          <a [queryParams]="{eq: relatedEquipment?.id}"
             [routerLink]="relatedProject | url">{{ relatedProject.name }}</a>
          <small *ngIf="projectFirmwareVersion"
                 title="Firmware Version"> (fw {{ projectFirmwareVersion }})</small>
        </h3>

        <h3 *ngIf="rma.related.length > 0"
            class="flex-row">
          <span>Related RMAs:</span>
          <div class="rma-list">
            <div *ngFor="let r of rma.related">
              &nbsp;<a [routerLink]="'../' + r.rmaNumber">{{ r.rmaNumber }}<small
              *ngIf="r.firmwareVersion" title="Firmware Version"> (fw {{ r.firmwareVersion }})</small>
            </a>
            </div>
          </div>
        </h3>


        <div>
          <mat-form-field class="firmware-version">
            <mat-label>Firmware Version</mat-label>
            <input formControlName="firmwareVersion" matInput placeholder="Ex. 4.12.0" type="text">
          </mat-form-field>
        </div>

        <h3>Total time spent in hours so far: {{ totalTimeSpentInHours() }}</h3>

        <h2>Status history</h2>
        <div formArrayName="statuses">
          <ng-container *ngFor="let statusForm of statuses.controls; let i = index; let last = last">
            <div [formGroup]="statusForm" class="status-form">
              <div class="flex-row">
                <mat-form-field class="no-bottom status">
                  <mat-label>Status</mat-label>
                  <mat-select formControlName="type">
                    <mat-option *ngFor="let s of rmaStatusTypes" [value]="s">{{ s }}</mat-option>
                  </mat-select>
                </mat-form-field>
                <button (click)="removeStatus(i)"
                        mat-icon-button>
                  <mat-icon>delete</mat-icon>
                </button>
              </div>

              <div>
                <mat-form-field class="status-date date no-bottom">
                  <mat-label>Date</mat-label>
                  <input
                    #statusDate="bsDatepicker"
                    bsDatepicker
                    formControlName="date"
                    matInput>
                  <mat-datepicker-toggle (click)="statusDate.toggle()"
                                         [class.mat-datepicker-toggle-active]="statusDate.isOpen"
                                         matIconSuffix></mat-datepicker-toggle>
                </mat-form-field>
                <mat-form-field class="time-spent no-bottom">
                  <mat-label>Time Spent</mat-label>
                  <input formControlName="timeSpentInHours" matInput min="0" placeholder="Ex. 1.5"
                         step="0.5"
                         type="number">
                </mat-form-field>
              </div>

              <div>
                <mat-form-field appearance="fill" class="description no-bottom">
                  <mat-label>Description</mat-label>
                  <textarea cdkAutosizeMaxRows="18" cdkAutosizeMinRows="1"
                            cdkTextareaAutosize
                            formControlName="description"
                            matInput></textarea>
                </mat-form-field>
              </div>
            </div>
            <mat-divider *ngIf="!last"></mat-divider>
          </ng-container>

          <button (click)="addStatus()" class="add"
                  mat-button>
            <mat-icon>add</mat-icon>
            Add status
          </button>
        </div>
      </div>
      <mat-divider vertical="true"></mat-divider>

      <div class="column loaned-items">
        <h2>Loaned Items</h2>

        <div formArrayName="loanedItems">
          <ng-container *ngFor="let loanedItemForm of loanedItems.controls; let i = index; let last = last">
            <div [formGroup]="loanedItemForm" class="loaned-item-form">
              <mat-autocomplete #catalogAuto="matAutocomplete"
                                (optionSelected)="autocompleteSelect($event, loanedItemForm)"
                                class="equipment-catalog-autocomplete" panelWidth="440px">
                <mat-option *ngFor="let eq of filteredEquipment | async" [id]="eq.id" [value]="eq.sku">
                                    <span>{{ eq.manufacturer }} <small>sku</small> {{ eq.sku }}
                                      <small>model</small> {{ eq.model }}</span>
                </mat-option>
              </mat-autocomplete>
              <div class="flex-row">
                <mat-form-field class="serial-number">
                  <mat-label>Serial Number</mat-label>
                  <input formControlName="serialNumber" matInput placeholder="Ex. 10001563324"
                         type="text">
                  <mat-error *ngIf="loanedItemForm.controls['serialNumber'].hasError('required')">
                    field <strong>required</strong>
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="manufacturer">
                  <mat-label>Manufacturer</mat-label>
                  <input formControlName="manufacturer" matInput placeholder="Ex. Meyer Sound"
                         type="text">
                  <mat-error *ngIf="loanedItemForm.controls['manufacturer'].hasError('required')">
                    field <strong>required</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <div>
                <mat-form-field class="sku">
                  <mat-label>SKU</mat-label>
                  <input [matAutocomplete]="catalogAuto" formControlName="sku" matInput
                         placeholder="Ex. 09.287.001.17"
                         type="text">
                  <mat-error *ngIf="loanedItemForm.controls['sku'].hasError('required')">
                    field <strong>required</strong>
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="model">
                  <mat-label>Model</mat-label>
                  <input formControlName="model" matInput placeholder="Ex. ULTRA-X40" type="text">
                  <mat-error *ngIf="loanedItemForm.controls['model'].hasError('required')">
                    field <strong>required</strong>
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="flex-row">

                <mat-form-field class="dispatch-date no-bottom">
                  <mat-label>Dispatch Date</mat-label>
                  <input
                    #dispatchDate="bsDatepicker"
                    bsDatepicker
                    formControlName="dispatchDate"
                    matInput>
                  <mat-datepicker-toggle (click)="dispatchDate.toggle()"
                                         [class.mat-datepicker-toggle-active]="dispatchDate.isOpen"
                                         matIconSuffix></mat-datepicker-toggle>
                </mat-form-field>
                <mat-form-field class="returned-date no-bottom">
                  <mat-label>Returned Date</mat-label>
                  <input
                    #returnedDate="bsDatepicker"
                    bsDatepicker
                    formControlName="returnedDate"
                    matInput>
                  <mat-datepicker-toggle (click)="returnedDate.toggle()"
                                         [class.mat-datepicker-toggle-active]="returnedDate.isOpen"
                                         matIconSuffix></mat-datepicker-toggle>
                </mat-form-field>

                <button (click)="removeLoanedItem(i)"
                        mat-icon-button>
                  <mat-icon>delete</mat-icon>
                </button>
              </div>

            </div>
            <mat-divider *ngIf="!last"></mat-divider>
          </ng-container>

          <button (click)="addLoanedItem()" class="add"
                  mat-button>
            <mat-icon>add</mat-icon>
            Add loaned item
          </button>
        </div>

        <h2>Spare Parts</h2>
        <app-equipment-table></app-equipment-table>
      </div>
    </div>
    <div class="form-actions flex">
      <button (click)="onSubmit()" [disabled]="editForm.pristine || !editForm.valid" color="primary"
              mat-raised-button>
        Save
      </button>
      <mat-hint *ngIf="editForm.pristine">All changes saved</mat-hint>
      <mat-hint *ngIf="editForm.dirty">Unsaved changes</mat-hint>
      <mat-error *ngIf="apiError" class="api-error">
        {{ apiError }}
      </mat-error>
    </div>
  </form>
</div>

