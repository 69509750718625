import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {Project, ProjectStatus} from "../../api/model/project";
import {Alert} from "../../api/model/alert";
import {NestedTreeControl} from "@angular/cdk/tree";
import {MatTreeNestedDataSource} from "@angular/material/tree";
import {concat, EMPTY} from "rxjs";
import {apiErrorToMessage, transformToProjectsWithChildren} from "../../util";
import {MatDialog} from "@angular/material/dialog";
import {ProjectService} from "../../api/project.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Options, SortableEvent} from "sortablejs";

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.sass']
})
export class ProjectListComponent implements OnChanges {
  @Input() projects: Project[] | undefined;
  @Input() alerts: Alert[] = [];
  @Input() expanded: boolean = true;
  @Input() showDeadline: boolean = true;
  @Input() editable: boolean = false;
  @Output() updated = new EventEmitter<Project>();
  treeControl = new NestedTreeControl<Project>(node => node.children);
  dataSource = new MatTreeNestedDataSource<Project>();
  sortableOptions: Options = {
    disabled: true,
    dropBubble: true,
    dataIdAttr: "id",
    group: "childProjects",
    forceFallback: true,
    fallbackClass: "dragged-item",
    onStart: (event: SortableEvent) => {
      console.log("AccountingIdWrapComponent sortable onStart", event.item.id, event);
      this.treeControl.collapse(this.projects?.find(p => p.id === event.item.id)!!);
    },
    onEnd: (event: SortableEvent) => {
      console.group("ProjectListComponent sortable onEnd");
      const projectId = event.item.id;
      const project = this.projects?.find(p => p.id === projectId)!!
      const parentId = event.to.dataset["parent"] || null;
      const parentProject = this.projects?.find(p => p.id === parentId)!!
      console.log("project being moved", event.item.id, project);
      console.log("target parent", parentId, parentProject);
      console.log("oldIndex, newIndex", event.oldIndex, event.newIndex);
      console.log("raw event", event);

      const childrenBefore = (parentProject?.children || this.treeControl.dataNodes).map(p => p.id);
      const children = childrenBefore.filter(id => id !== projectId);
      children.splice(event.newIndex || 0, 0, projectId);
      const sortingUpdate = children.map((id, index) => {
        return {projectId: id, sort: index};
      });
      console.log("childrenBefore", childrenBefore)
      console.log("sortingUpdate", sortingUpdate)
      console.groupEnd();

      concat(
        (project.parentId !== parentId ? this.projectService.updateParent(project.id, parentId) : EMPTY),
        this.projectService.updateSorting({sorting: sortingUpdate}),
      ).subscribe({
        next: (response) => {
          console.log("ProjectListComponent sortable onEnd request worked", response);
        },
        error: error => {
          const msg = apiErrorToMessage(error);
          this._snackBar.open(`Could not update parent or sorting order of projects. ${msg}`, "okay... :(");
          return EMPTY
        },
        complete: () => {
          this.updated.emit();
        }
      });
    },
  };

  constructor(public dialog: MatDialog,
              private projectService: ProjectService,
              private _snackBar: MatSnackBar,
  ) {
  }

  hasChild = (_: number, node: Project) => !!node.children && node.children.length > 0;

  ngOnChanges(changes: SimpleChanges): void {
    console.log("ProjectListComponent ngOnChanges", changes);
    if (changes["projects"] && changes["projects"].currentValue) {
      this.updateProjects(changes["projects"].currentValue as Project[]);
    }
    if (changes["editable"] && changes["editable"].currentValue) {
      this.sortableOptions.disabled = false;
    }
  }

  private updateProjects(projects: Project[]) {
    console.log("ProjectListComponent list component init with projects", projects)
    const sortOrder = [ProjectStatus.ACTIVE, ProjectStatus.PENDING, ProjectStatus.COMPLETE];
    this.projects = projects.sort((a, b) =>
      sortOrder.indexOf(a.status) - sortOrder.indexOf(b.status))
      .sort((a, b) => a.sort - b.sort);

    const topLevelProjects = transformToProjectsWithChildren(this.projects);
    this.dataSource.data = topLevelProjects;
    this.treeControl.dataNodes = topLevelProjects;
    if (this.expanded) {
      this.treeControl.expandAll();
    }
  }

}
