import {Component} from '@angular/core';
import {UserCreateUpdateRequest, UserInfo} from "../../api/model/user";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../../api/auth.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {HttpErrorResponse} from "@angular/common/http";
import {ApiError} from "../../api/model/common";
import {EMPTY} from "rxjs";

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.sass']
})
export class UserProfileComponent {
  user: UserInfo | null | undefined;
  userProfileForm = new FormGroup({
    name: new FormControl("", [Validators.required]),
    oldPassword: new FormControl("", [Validators.minLength(5)]),
    newPassword: new FormControl("", [Validators.minLength(5)]),
  })
  apiError: string | undefined;
  private emailLinkId: string = "";

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private _snackBar: MatSnackBar,
  ) {
    authService.getCurrentUser().subscribe({
      next: user => {
        this.user = user;
        this.userProfileForm.controls.name.setValue(user?.name || "");
      },
      error: error => this.apiError = ((error as HttpErrorResponse).error as ApiError).message || (error as HttpErrorResponse).message,
    })
  }

  onSubmit() {
    if (this.userProfileForm.valid) {
      this.apiError = undefined;
      const formData = this.userProfileForm.getRawValue();

      const request = formData as UserCreateUpdateRequest;

      this.authService.editUser(this.user!.id, request)
        .subscribe({
          next: (user) => {
            console.log("UserProfileComponent changed user", user);
            this._snackBar.open(`Great success! Changes saved...`,
              undefined, {duration: 10000});
            this.userProfileForm.controls.oldPassword.setValue(null);
            this.userProfileForm.controls.newPassword.setValue(null);
            this.userProfileForm.markAsPristine();
          },
          error: error => {
            console.log("RegisterComponent failed to register", error)
            this.apiError = ((error as HttpErrorResponse).error as ApiError).message || (error as HttpErrorResponse).message;
            return EMPTY;
          }
        });
    }
  }
}
