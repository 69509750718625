import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from "@angular/router";
import {AuthService} from "./api/auth.service";
import {UserInfo} from "./api/model/user";
import {BuildDetailsService} from "./api/build.service";
import {interval, Observable} from "rxjs";
import {AlertService} from "./api/alert.service";
import {Alert} from "./api/model/alert";
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  title = 'Sonus Space';

  alerts: Alert[] = [];
  buildNumber: String = this.buildDetailsService.buildNumberAtCompile
  buildNumberNew$: Observable<String> = this.buildDetailsService.newBuildNumberDetected
  buildIsUpToDate$: Observable<boolean> = this.buildDetailsService.buildIsUpToDateSubject
  isLoggedIn: boolean = false;
  portrait: boolean = false;
  searchVisible: boolean = true;
  links = [
    {path: '/project', label: 'Project'},
    {path: '/production', label: 'Production'},
    {path: '/sales', label: 'Sales'},
    {path: '/equipment', label: 'Equipment'},
    {path: '/service', label: 'Service'},
  ];

  constructor(private router: Router, private authService: AuthService,
              private responsive: BreakpointObserver,
              private alertService: AlertService,
              private buildDetailsService: BuildDetailsService) {
    interval(30000).subscribe(value => {
      console.log("loading alerts on timer");
      this.loadAlerts()
    })
    this.authService.isLoggedIn().subscribe(loggedIn => this.isLoggedIn = loggedIn);
  }

  ngOnInit() {
    this.loadAlerts();
    this.responsive.observe([
      Breakpoints.HandsetPortrait,
      Breakpoints.TabletPortrait,
    ])
      .subscribe(result => {
        this.searchVisible = true;
        this.portrait = false;
        if (result.matches) {
          this.searchVisible = false;
          this.portrait = true;
        }
      });
  }

  currentUser(): UserInfo | null | undefined {
    return this.authService.currentUser;
  }

  logout() {
    this.authService.logout();
    this.router.navigate(["/login"])
      .then(() => {
        window.location.reload();
      });
  }

  alertBadge() {
    return this.alerts.length > 0 ? this.alerts.length : '';
  }

  loadAlerts() {
    this.alertService.getAlerts().subscribe(alerts => this.alerts = alerts);
  }

  showSearch() {
    this.searchVisible = true;
  }
}
