<mat-form-field appearance="fill" class="responsible no-bottom">
  <mat-label>Responsible</mat-label>
  <mat-chip-grid #chipGrid [required]="required" aria-label="User selection">
    <mat-chip-row (removed)="remove(r)" *ngFor="let r of control.value">
      <app-user [email]="r"></app-user>
      <small> {{ r }}</small>
      <button [attr.aria-label]="'remove ' + r" matChipRemove>
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-row>
    <input #responsibleInput (matChipInputTokenEnd)="add($event)"
           [matAutocomplete]="auto" [matChipInputFor]="chipGrid"
           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
           placeholder="Add Person..."/>
  </mat-chip-grid>

  <ng-content></ng-content>

  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let user of filteredUsers" [value]="user.email">
      {{ user.name }} <small>{{ user.email }}</small>
    </mat-option>
  </mat-autocomplete>

  <mat-hint *ngIf="hint" [matTooltip]="hintTooltip">{{ hint }}</mat-hint>
</mat-form-field>
