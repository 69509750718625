<h1 mat-dialog-title>
  Bulk Editing <strong>{{ data.equipment.length }}</strong> items
</h1>

<div class="dense-1" mat-dialog-content>
  <app-equipment-condense-table [equipment]="data.equipment"></app-equipment-condense-table>
  <form (ngSubmit)="onSubmit()" [formGroup]="bulkEditForm" class="add-edit-equipment-form">
    <div class="column">
      <div>
        <mat-form-field class="status">
          <mat-label>Status</mat-label>
          <mat-select formControlName="status">
            <mat-option *ngFor="let s of statuses" [value]="s">{{ s | readable }}</mat-option>
          </mat-select>
          <mat-hint [matTooltip]="tooltip(valueSets.status)">{{ hint(valueSets.status) }}</mat-hint>
        </mat-form-field>
      </div>
      <div>
        <app-user-field [control]="bulkEditForm.controls.responsible"
                        [hintTooltip]="tooltipArray(valueSets.responsible)"
                        [hint]="hint(valueSets.responsible)">
        </app-user-field>
        <!--
                <mat-form-field class="responsible">
                  <mat-label>Responsible</mat-label>
                  <input formControlName="responsible" matInput placeholder="Ex. Fabas" type="text">

                </mat-form-field>-->
      </div>
    </div>
    <mat-divider [vertical]="true"></mat-divider>
    <div class="column">

      <div class="row">
        <mat-form-field class="purchase-order-number">
          <mat-label>Purchase Order Number</mat-label>
          <input formControlName="purchaseOrderNumber" matInput placeholder="Ex. PN127757-3" type="text">
          <mat-hint
            [matTooltip]="tooltip(valueSets.purchaseOrderNumber)">{{ hint(valueSets.purchaseOrderNumber) }}
          </mat-hint>
        </mat-form-field>
        <mat-form-field appearance="fill" class="purchase-order-date date">
          <mat-label>Purchase Order Date</mat-label>
          <input
            #purchaseOrderDate="bsDatepicker"
            (bsValueChange)="datePickerValueChange($event, 'purchaseOrderDate')"
            bsDatepicker
            formControlName="purchaseOrderDate"
            matInput>
          <mat-hint
            [matTooltip]="tooltipDate(valueSets.purchaseOrderDate)">{{ hint(valueSets.purchaseOrderDate) }}
          </mat-hint>
          <mat-datepicker-toggle (click)="purchaseOrderDate.toggle()"
                                 [class.mat-datepicker-toggle-active]="purchaseOrderDate.isOpen"
                                 matIconSuffix></mat-datepicker-toggle>
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field class="sales-order-confirmation-number">
          <mat-label>Sales Order Confirmation Number</mat-label>
          <input formControlName="salesOrderConfirmationNumber" matInput placeholder="Ex. PN127757-3" type="text">
          <mat-hint
            [matTooltip]="tooltip(valueSets.salesOrderConfirmationNumber)">{{ hint(valueSets.salesOrderConfirmationNumber) }}
          </mat-hint>
        </mat-form-field>
        <mat-form-field appearance="fill" class="sales-order-confirmation-date date">
          <mat-label>Sales Order Confirmation Date</mat-label>
          <input
            #salesOrderConfirmationDate="bsDatepicker"
            (bsValueChange)="datePickerValueChange($event, 'salesOrderConfirmationDate')"
            bsDatepicker
            formControlName="salesOrderConfirmationDate"
            matInput>
          <mat-hint
            [matTooltip]="tooltipDate(valueSets.salesOrderConfirmationDate)">{{ hint(valueSets.salesOrderConfirmationDate) }}
          </mat-hint>
          <mat-datepicker-toggle (click)="salesOrderConfirmationDate.toggle()"
                                 [class.mat-datepicker-toggle-active]="salesOrderConfirmationDate.isOpen"
                                 matIconSuffix></mat-datepicker-toggle>
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field appearance="fill" class="estimated-ship-by-date date">
          <mat-label>Est. Ship By</mat-label>
          <input
            #estimatedShipByDate="bsDatepicker"
            (bsValueChange)="datePickerValueChange($event, 'estimatedShipByDate')"
            bsDatepicker
            formControlName="estimatedShipByDate"
            matInput>
          <mat-hint
            [matTooltip]="tooltipDate(valueSets.estimatedShipByDate)">{{ hint(valueSets.estimatedShipByDate) }}
          </mat-hint>
          <mat-datepicker-toggle (click)="estimatedShipByDate.toggle()"
                                 [class.mat-datepicker-toggle-active]="estimatedShipByDate.isOpen"
                                 matIconSuffix></mat-datepicker-toggle>
        </mat-form-field>

        <mat-form-field appearance="fill" class="received-on date">
          <mat-label>Received On</mat-label>
          <input
            #receivedOnDate="bsDatepicker"
            (bsValueChange)="datePickerValueChange($event, 'receivedOnDate')"
            bsDatepicker
            formControlName="receivedOnDate"
            matInput>
          <mat-hint [matTooltip]="tooltipDate(valueSets.receivedOnDate)">{{ hint(valueSets.receivedOnDate) }}</mat-hint>
          <mat-datepicker-toggle (click)="receivedOnDate.toggle()"
                                 [class.mat-datepicker-toggle-active]="receivedOnDate.isOpen"
                                 matIconSuffix></mat-datepicker-toggle>
        </mat-form-field>
      </div>

      <div class="row">
        <mat-form-field class="invoice-number">
          <mat-label>Sonus Invoice Number</mat-label>
          <input formControlName="invoiceNumber" matInput placeholder="Ex. SON0054698" type="text">
          <mat-hint
            [matTooltip]="tooltip(valueSets.invoiceNumber)">{{ hint(valueSets.invoiceNumber) }}
          </mat-hint>
        </mat-form-field>
        <mat-form-field appearance="fill" class="invoice-date date">
          <mat-label>Sonus Invoice Date</mat-label>
          <input
            #invoiceDate="bsDatepicker"
            (bsValueChange)="datePickerValueChange($event, 'invoiceDate')"
            bsDatepicker
            formControlName="invoiceDate"
            matInput>
          <mat-hint [matTooltip]="tooltipDate(valueSets.invoiceDate)">{{ hint(valueSets.invoiceDate) }}</mat-hint>
          <mat-datepicker-toggle (click)="invoiceDate.toggle()"
                                 [class.mat-datepicker-toggle-active]="invoiceDate.isOpen"
                                 matIconSuffix></mat-datepicker-toggle>
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field appearance="fill" class="installed-date date">
          <mat-label>Installed Date</mat-label>
          <input
            #installedDate="bsDatepicker"
            (bsValueChange)="datePickerValueChange($event, 'installedDate')"
            bsDatepicker
            formControlName="installedDate"
            matInput>
          <mat-hint [matTooltip]="tooltipDate(valueSets.installedDate)">{{ hint(valueSets.installedDate) }}</mat-hint>
          <mat-datepicker-toggle (click)="installedDate.toggle()"
                                 [class.mat-datepicker-toggle-active]="installedDate.isOpen"
                                 matIconSuffix></mat-datepicker-toggle>
        </mat-form-field>
        <mat-form-field appearance="fill" class="warranty-date date">
          <mat-label>Warranty Date</mat-label>
          <input
            bsDatepicker
            formControlName="warrantyDate"
            matInput>
          <mat-hint [matTooltip]="tooltipDate(valueSets.warrantyDate)">{{ hint(valueSets.warrantyDate) }}</mat-hint>
        </mat-form-field>
      </div>
    </div>
  </form>
</div>
<div class="equipment-add-edit-dialog-actions" mat-dialog-actions>
  <button (click)="onCancelClick()" [color]="bulkEditForm.dirty ? 'warn' : ''"
          mat-button>Cancel
  </button>
  <button (click)="onPrimaryClick()" [disabled]="!bulkEditForm.valid" color="primary" mat-raised-button>
    Save
  </button>
  <button (click)="onDeleteClick()" *ngIf="data.equipment" class="delete" mat-button type="button">Delete</button>
  <mat-error *ngIf="apiError" class="api-error">
    {{ apiError }}
  </mat-error>
</div>
