import {Observable} from "rxjs";
import {CanDeactivateFn, UrlTree} from "@angular/router";
import {inject} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

// src https://stackoverflow.com/a/75769104
export const pendingChangesGuard: CanDeactivateFn<ComponentCanDeactivate> = (
  component: ComponentCanDeactivate
): Observable<boolean | UrlTree> => {
  const dialog = inject(MatDialog);

  return new Observable<boolean | UrlTree>((obs) => {
    // if there are no pending changes, just allow deactivation; else confirm first

    if (component.canDeactivate && component.canDeactivate()) {
      obs.next(true);
    } else {
      // NOTE: this warning message will only be shown when navigating elsewhere within your angular app;
      // when navigating away from your angular app, the browser will show a generic warning message
      // see http://stackoverflow.com/a/42207299/7307355
      if (confirm(
        'WARNING: You have unsaved changes. Press Cancel to go back and save these changes, or OK to lose these changes.'
      )) {
        dialog.closeAll();
        obs.next(true);
      } else {
        obs.next(false);
      }
    }
  });
};
