import {Component, Input} from '@angular/core';
import {Project} from "../../api/model/project";

@Component({
  selector: 'app-project-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.sass']
})
export class DetailsComponent {
  @Input() project!: Project;
}
