import {Pipe, PipeTransform} from '@angular/core';
import {Project, ProjectType} from "../api/model/project";
import {ProjectInfo} from "../api/model/alert";

@Pipe({
    name: 'url'
})
export class UrlPipe implements PipeTransform {
    transform(project: ProjectInfo | Project, exact: Boolean = true): string {
        return projectUrl(project, exact)
    }
}

export function projectUrl(project: ProjectInfo | Project, exact: Boolean = true): string {
    const firstPart = project.type == ProjectType.PUBLIC ? 'project' : project.type.toLowerCase();
    return `/${firstPart}/${project.accountingId}` + (exact ? `/${project.id}` : ``);
}
