import {Component, Input} from '@angular/core';
import {Equipment} from "../../api/model/equipment";

@Component({
  selector: 'app-equipment-condense-table',
  templateUrl: './condense-table.component.html',
  styleUrls: ['./condense-table.component.sass']
})
export class CondenseTableComponent {
  @Input() equipment!: Equipment[];
}
