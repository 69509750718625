import {Component, Input, OnInit} from '@angular/core';
import {Project, ProjectStatus} from "../../api/model/project";
import {ThemePalette} from "@angular/material/core";

@Component({
  selector: 'app-project-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.sass']
})
export class StatusComponent implements OnInit {
  @Input() project!: Project;
  @Input() condense: Boolean = false;
  status!: string;
  tooltip!: string;

  ngOnInit(): void {
    this.status = this.resolveStatusFromChildren(this.project);
    this.tooltip = 'Status '
      + (this.project.status === "AUTO" && this.status !== "AUTO" ? "AUTO -> " : "")
      + this.status;
  }

  iconColor(): ThemePalette {
    return this.status == ProjectStatus.ACTIVE ? 'warn' :
      (this.status == ProjectStatus.COMPLETE ? 'primary' : undefined);
  }

  isYellow(): boolean {
    return this.status == ProjectStatus.PENDING;
  }

  private resolveStatusFromChildren(project: Project): string {
    if (project.status === ProjectStatus.AUTO && project.children.length > 0) {
      if (project.children.some(p => p.status === ProjectStatus.ACTIVE
        || this.resolveStatusFromChildren(p) === ProjectStatus.ACTIVE)) {
        return ProjectStatus.ACTIVE;
      } else if (project.children.some(p => p.status === ProjectStatus.PENDING
        || this.resolveStatusFromChildren(p) === ProjectStatus.PENDING)) {
        return ProjectStatus.PENDING;
      } else if (project.children.every(p => p.status === ProjectStatus.COMPLETE
        || this.resolveStatusFromChildren(p) === ProjectStatus.COMPLETE)) {
        return ProjectStatus.COMPLETE;
      }
    }
    return project.status;
  }
}
