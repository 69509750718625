<div class="overview-wrapper">
  <div class="column details">
      <mat-card class="card">
        <mat-card-header>
          <mat-card-title>Details</mat-card-title>
        </mat-card-header>
        <mat-card-content class="dashboard-card-content">
          <p>
            Client language: {{ project.details.clientPreferredLanguage }}<br/>
            location: <a [href]="project.details.location.mapsUrl" target="_blank">{{ project.details.location.address }}</a><br/>
            dropbox: <a [href]="project.details.dropboxProjectLink" target="_blank">project folder</a><br/>
            project completed: {{ project.details.projectCompletedDate | date:'y-MM-dd HH:mm' }}
          </p>
        </mat-card-content>
      </mat-card>

      <mat-card class="card scrollable">
        <mat-card-header>
          <mat-card-title>Description</mat-card-title>
        </mat-card-header>
        <mat-card-content class="dashboard-card-content">
          <div class="description" [innerHTML]="project.details.description"></div>
        </mat-card-content>
      </mat-card>
  </div>
  <mat-card class="column card tasks">
    <mat-card-header>
      <mat-card-title>Tasks</mat-card-title>
      <span class="unsaved">unsaved</span>
    </mat-card-header>
    <mat-card-content class="dashboard-card-content scrollable">
      <app-tasks [forId]="project.id"></app-tasks>
    </mat-card-content>
  </mat-card>
  <mat-card class="column card">
    <mat-card-header>
      <mat-card-title>Comments</mat-card-title>
    </mat-card-header>
    <mat-card-content class="dashboard-card-content">
      <app-comments for="PROJECT" [forId]="project.id"></app-comments>
    </mat-card-content>
  </mat-card>

</div>
