<div class="row" style="opacity: .5" matTooltip="out of immediate scope">

  <mat-chip-set class="aligned">
    <mat-chip *ngFor="let tag of tags">
      {{ tag.name }}
      <button matChipRemove aria-label="Remove tag" (click)="remove(tag)">
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip>
  </mat-chip-set>

  <div class="aligned">
    <button mat-icon-button aria-label="Add new tag" #newTagTrigger [matMenuTriggerFor]="menu">
      <mat-icon>add</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <div (click)="$event.stopPropagation()">

      <form [formGroup]="newTagForm" (ngSubmit)="onSubmit()">
        <mat-form-field class="no-bottom">
          <mat-label>New tag</mat-label>
          <input matInput formControlName="name" placeholder="Ex. Sound">
        </mat-form-field>
      </form>
      </div>

    </mat-menu>
  </div>

</div>
