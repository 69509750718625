import {Component} from '@angular/core';
import {EquipmentCatalogService} from "../../api/equipment-catalog.service";
import {EquipmentCatalog, EquipmentCatalogCreateUpdateRequest} from "../../api/model/equipment-catalog";
import {Papa, ParseResult} from "ngx-papaparse";
import {MatSnackBar} from "@angular/material/snack-bar";
import {HttpErrorResponse} from "@angular/common/http";
import {ApiError} from "../../api/model/common";
import {EMPTY} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {EquipmentGroup} from "../../api/model/equipment";

@Component({
    selector: 'app-equipment-catalog',
    templateUrl: './equipment-catalog.component.html',
    styleUrls: ['./equipment-catalog.component.sass']
})
export class EquipmentCatalogComponent {
    displayedColumns: string[] = ['manufacturer', 'sku', 'model', 'description', 'hyperlink', 'delete'];
    catalog: EquipmentCatalog[] = [];
    catalogGroup: EquipmentGroup = EquipmentGroup.PROJECT;
    catalogGroupEnum: typeof EquipmentGroup = EquipmentGroup;

    exampleCsvData: string;
    apiError: string | undefined;

    imported: {
        file: File,
        equipmentCatalog: EquipmentCatalogCreateUpdateRequest[],
    } | undefined;

    constructor(
        private route: ActivatedRoute,
        private equipmentCatalogService: EquipmentCatalogService,
        private papa: Papa,
        private _snackBar: MatSnackBar,
    ) {
        this.route.data.subscribe(data => {
            this.catalog = [];
            this.catalogGroup = data['group'] as EquipmentGroup;
            this.loadCatalog();
        });

        const rows = [
            [
                "Manufacturer",
                "SKU",
                "Model",
                "Description",
                "Hyperlink",
            ],
            [
                "Sonus", // 0
                "40.287.039.01", // 1
                "EW-D ASA SPLITTER", // 2
                "Multiline description\nLine 2\nLine 3", // 3
                "https://sonus.lt/", // 4
            ],
        ];
        this.exampleCsvData = "data:text/csv;charset=utf-8," + papa.unparse(rows, {delimiter: ";"});
        //rows.map(e => e.join(",")).join("\n");
    }

    catalogGroupTitle(): string {
        return this.catalogGroup == EquipmentGroup.SERVICE ? "Service Spare Parts" : "Equipment";
    }

    csvInputChange($event: Event) {
        // TODO add loading spinner
        this.apiError = undefined;
        this.imported = undefined;
        const file = ($event.target as any).files[0] as File;
        file.text().then(contents => {
            const parsed = this.papa.parse(contents, {delimiter: ";"});
            if (parsed.errors.length > 0) {
                this.apiError = parsed.errors.map(e => e.message).join("; ");
                return;
            }
            const request = this.parsedCsvToData(parsed);
            if (request.length === 0) {
                this.apiError = "Bad or empty .csv file submitted. Make sure you use semicolons as separators (;)";
                return;
            }
            console.log("EquipmentCatalogComponent csvInputChange", $event, file, parsed, request);
            this.equipmentCatalogService.bulkWrite(request).subscribe({
                next: (_) => {
                    console.log("EquipmentCatalogComponent imported succesfully", request);
                    this.imported = {file, equipmentCatalog: request};
                    this._snackBar.open(`Great success! ${request.length} items imported to equipment catalog...`, undefined, {duration: 10000});
                    this.loadCatalog();
                },
                error: error => {
                    console.log("EquipmentCatalogComponent API call failed", error)
                    this.apiError = ((error as HttpErrorResponse).error as ApiError).message || (error as HttpErrorResponse).message;
                    return EMPTY;
                }
            });
        });
    }

    deleteItem(e: EquipmentCatalog) {
        if (confirm("Are you sure you want to delete item with " + e.sku + " sku from the catalog?")) {
            this.equipmentCatalogService.delete(e.id).subscribe(
                _ => this.loadCatalog()
            )
        }
    }

    exampleFilename(): string {
        const group = this.catalogGroupTitle().toLowerCase().replaceAll(" ", "-")
        return `example-${group}-catalog-import.csv`
    }

    private parsedCsvToData(csv: ParseResult): EquipmentCatalogCreateUpdateRequest[] {
        const csvNoHeaders = (csv.data.slice(1) as string[][]).filter(line => line.length > 2 && line[0] != "");

        return csvNoHeaders.map(line => {
            return {
                group: this.catalogGroup,
                manufacturer: line[0],
                sku: line[1],
                model: line[2],
                description: line[3],
                hyperlink: line[4],
            };
        });
    }

    private loadCatalog() {
        this.equipmentCatalogService.list(this.catalogGroup).subscribe(
            catalog => this.catalog = catalog
        )
    }
}
