import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {Alert} from "./model/alert";

@Injectable()
export class AlertService {
  constructor(private http: HttpClient) {
  }

  getAlerts(): Observable<Alert[]> {
    return this.http.get<Alert[]>(environment.apiUrl + "/alert")
  }
}
