<mat-form-field class="no-bottom global-search">
  <mat-icon matIconPrefix>search</mat-icon>
  <mat-label>Search Sonus Space</mat-label>
  <input #search [formControl]="searchControl" [matAutocomplete]="searchAuto" matInput placeholder="" type="text">
  <mat-icon matSuffix
            matTooltip="Searches projects, equipment, service RMAs. Global keyboard shortcut to focus this search /">
    help
  </mat-icon>
</mat-form-field>

<mat-autocomplete #searchAuto="matAutocomplete" (optionSelected)="searchOptionSelected($event)"
                  class="global-search-autocomplete">
  <ng-container *ngFor="let group of searchResults | async">
    <mat-option *ngIf="group && !group.items" [disabled]="!group.value" [value]="group.value">
      {{ group.label }}
    </mat-option>
    <mat-optgroup *ngIf="group && group.items" [label]="group.label">
      <mat-option *ngFor="let res of group.items" [value]="res.value">
        {{ res.label }}
      </mat-option>
    </mat-optgroup>
  </ng-container>
</mat-autocomplete>
