<div class="breadcrumb-container">
    <a class="home" routerLink="/">
        <mat-icon>home</mat-icon>
    </a>
    <span class="separator"> > </span>

    <ng-container *ngFor="let breadcrumb of (breadcrumbs$ | async); let last = last">
        <a [class.active]="last" [routerLink]="breadcrumb.url" [title]="breadcrumb.label">{{ breadcrumb.label }}</a>
        <span *ngIf="!last" class="separator"> > </span>
    </ng-container>
</div>
