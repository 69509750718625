import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {Task, TaskCreateRequest, TaskUpdateRequest} from "./model/task";
import {BelongsTo} from "./model/common";

@Injectable()
export class TaskService {
  constructor(private http: HttpClient) {
  }

  list(belongsToId: string, belongsTo: BelongsTo = BelongsTo.PROJECT): Observable<Task[]> {
    return this.http.get<Task[]>(environment.apiUrl + "/task?belongsTo=" + belongsTo + "&belongsToId=" + belongsToId)
  }

  create(request: TaskCreateRequest): Observable<Task> {
    return this.http.post<Task>(environment.apiUrl + "/task", request)
  }

  delete(id: string): Observable<any> {
    return this.http.delete(environment.apiUrl + "/task/" + id)
  }

  update(id: string, request: TaskUpdateRequest): Observable<Task> {
    return this.http.put<Task>(environment.apiUrl + "/task/" + id, request)
  }
}
