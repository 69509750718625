<div class="comment-container">
  <ng-container *ngIf="comments === undefined">
    Loading comments...
    <!-- TODO prettify loading screens -->
  </ng-container>

  <div *ngIf="for" class="comment new-comment">
    <textarea #newCommentInput="ngModel" [(ngModel)]="newComment" cdkTextareaAutosize
              class="inline-input"
              placeholder="add new comment"
              type="text"></textarea>
    <button (click)="$event.stopPropagation(); addComment(newCommentInput)"
            class="action" color="primary" mat-button
            type="button">
      Comment
    </button>
  </div>

  <div *ngFor="let c of comments; let last = last" class="comment">
    <span class="author">{{c.created.by}}</span><span *ngIf="!for"> for
      <span *ngIf="c.belongsToEquipmentProjectCode">eq. {{c.belongsToEquipmentProjectCode}} in </span>
      <a [routerLink]="'/project/' + c.belongsToProjectId">{{c.belongsToProjectAccountingId}}</a>
    </span>:
    <span class="message">{{c.message}}</span>
    <div class="time">({{c.created.at | date:'y-MM-dd HH:mm'}})</div>
    <mat-divider *ngIf="!last"></mat-divider>
  </div>

</div>
