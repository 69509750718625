import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {BreakpointObserver} from '@angular/cdk/layout';
import {ProjectService} from "../api/project.service";
import {Router} from "@angular/router";
import {Project, ProjectType} from "../api/model/project";
import {AuthService} from "../api/auth.service";
import {Alert} from "../api/model/alert";
import {AlertService} from "../api/alert.service";

@Component({
  selector: 'app-dash',
  templateUrl: './dash.component.html',
  styleUrls: ['./dash.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class DashComponent implements OnInit {
  /** Based on the screen size, switch from standard to one column per row */
  /*cards = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
  );*/
  alerts: Alert[] = [];
  publicAlerts: Alert[] = [];
  productionAlerts: Alert[] = [];
  salesAlerts: Alert[] = [];

  projects: Project[] | undefined;
  bookmarked: string[] = [];

  constructor(private router: Router,
              private authService: AuthService,
              private projectService: ProjectService,
              private alertService: AlertService,
              private breakpointObserver: BreakpointObserver) {
  }

  ngOnInit(): void {
    this.authService.getCurrentUser().subscribe(user => {
      this.bookmarked = user?.bookmarkedProjects || [];
      this.projectService.listBookmarked().subscribe(
        projects => {
          this.projects = projects;
        })
    });
    this.alertService.getAlerts().subscribe(alerts => {
      this.alerts = alerts;
      this.publicAlerts = alerts.filter(a => a.project.type == ProjectType.PUBLIC);
      this.productionAlerts = alerts.filter(a => a.project.type == ProjectType.PRODUCTION);
      this.salesAlerts = alerts.filter(a => a.project.type == ProjectType.SALES);
    });
  }
}
