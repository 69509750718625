import {ActivatedRouteSnapshot, ResolveFn, Router} from "@angular/router";
import {inject} from "@angular/core";
import {EMPTY, mergeMap, of} from "rxjs";
import {RmaService} from "../api/rma.service";
import {Rma} from "../api/model/rma";

export const rmaResolver: ResolveFn<Rma> = (route: ActivatedRouteSnapshot) => {
  const router = inject(Router);
  const rmaService = inject(RmaService);
  const rmaNumber = route.paramMap.get('rmaNumber')!;

  return rmaService.getByNum(rmaNumber).pipe(mergeMap(rma => {
    if (rma) {
      console.log("rmaResolver", rma)
      return of(rma);
    } else {  // id not found
      router.navigate(['/']);
      return EMPTY;
    }
  }));
};
