<div [ngClass]="context" class="equipment-table-container">

  <div class="buttons-container dense-minimum">
    <div [matTooltipDisabled]="!isLocked" matTooltip="Can not add to a locked project">
      <button (click)="openAddDialog(projectIds[0])"
              *ngIf="context == contextEnum.SINGLE_PROJECT || context == contextEnum.SERVICE_SPARE_PARTS"
              [disabled]="isLocked"
              [matTooltip]="project?.locked ? '' : ''"
              color="primary"
              mat-mini-fab>
        <mat-icon>add</mat-icon>
      </button>
    </div>

    <ng-container *ngIf="selection.selected.length > 0">
      Selected: {{ selection.selected.length }}
      <button (click)="openEditDialogBySelection()" [disabled]="selection.selected.length == 0" class="edit"
              color="accent"
              mat-mini-fab
              matTooltip="Edit">
        <mat-icon>edit</mat-icon>
      </button>

      <div [matTooltip]="assignButtonTooltip()">
        <button (click)="openAssignDialogBySelection()"
                *ngIf="context != contextEnum.SERVICE_SPARE_PARTS && context != contextEnum.ALL_EQUIPMENT"
                [disabled]="!selectedOnlyAssignable()"
                class="edit"
                color="accent"
                mat-mini-fab>
          <mat-icon>open_in_new</mat-icon>
        </button>
      </div>

      <div [matTooltip]="unassignButtonTooltip()">
        <button (click)="unassignSelection()"
                *ngIf="context != contextEnum.SERVICE_SPARE_PARTS && context != contextEnum.ALL_EQUIPMENT"
                [disabled]="!selectedOnlyAssigned()"
                class="edit"
                color="accent"
                mat-mini-fab>
          <mat-icon>open_in_new_off</mat-icon>
        </button>
      </div>

      <div [matTooltip]="moveButtonTooltip()">
        <button (click)="openMoveToDialogBySelection()"
                *ngIf="context != contextEnum.SERVICE_SPARE_PARTS"
                [disabled]="isLocked || !selectedOnlyCopyable() || selection.selected.length == 0"
                class="edit"
                color="accent"
                mat-mini-fab>
          <mat-icon>move_to_inbox</mat-icon>
        </button>
      </div>

      <div [matTooltip]="copyButtonTooltip()" matTooltip="">
        <button (click)="openCopyDialogBySelection()"
                *ngIf="context != contextEnum.SERVICE_SPARE_PARTS"
                [disabled]="isLocked || !selectedOnlyCopyable() || selection.selected.length == 0"
                class="edit"
                color="accent"
                mat-mini-fab>
          <mat-icon>content_copy</mat-icon>
        </button>
      </div>
      <div class="spacer"></div>
    </ng-container>

    <mat-form-field *ngIf="selection.selected.length == 0" class="no-bottom filter with-floating-label">
      <mat-label>Text Search</mat-label>
      <input #filter [(ngModel)]="filterValue" matInput
             placeholder='Enter search criteria, type - to exclude or wrap in " for exact match. Ex. Ultra -MUB "07"'
             type="text">
      <button (click)="clearFilter()" aria-label="Clear" mat-icon-button matSuffix>
        <mat-spinner *ngIf="filterLoading" class="progress-spinner" diameter="24" matSuffix></mat-spinner>
        <mat-icon *ngIf="!filterLoading"
                  [class.empty]="isFilterEmpty()">close
        </mat-icon>
      </button>
    </mat-form-field>

    <button [matMenuTriggerFor]="columnsMenu" class="float-right" mat-icon-button matSuffix
            matTooltip="Show/hide columns">
      <mat-icon>more_vert</mat-icon>
    </button>

    <mat-menu #columnsMenu="matMenu">
      <strong mat-menu-item>Show/hide columns</strong>
      <ng-container *ngFor="let c of columns">
        <div (click)="toggleColumnVisibility(c); $event.stopPropagation()" *ngIf="!c.hidden" mat-menu-item>
          <mat-checkbox [checked]="c.visible">{{ c.label }}</mat-checkbox>
        </div>
      </ng-container>
      <button (click)="resetColumnVisibility()" mat-menu-item>
        <mat-icon>restart_alt</mat-icon>
        <span>Reset to defaults</span>
      </button>
    </mat-menu>
  </div>
  <ng-template #skuContext let-equipment="equipment">
    <div cdkMenu
         class="mat-mdc-menu-panel mdc-menu-surface mdc-menu-surface--open mat-elevation-z8">
      <div class="mat-mdc-menu-content mdc-list">
        <button (click)="copyToClipboard([equipment])" cdkMenuItem mat-menu-item>
          <mat-icon>content_copy</mat-icon>
          <span>Copy</span>
        </button>
        <button (click)="copyToClipboard(selection.selected)" [disabled]="selection.selected.length === 0"
                cdkMenuItem
                mat-menu-item>
          <mat-icon>copy_all</mat-icon>
          <span>Copy {{ selection.selected.length }} selected</span>
        </button>
      </div>
    </div>
  </ng-template>

  <section [class.loading]="filterLoading" class="equipment-container mat-elevation-z8 dense-minimum"
           tabindex="0">
    <div *ngIf="filterLoading" class="loading-indicator">
      <mat-progress-spinner color="accent" diameter="50" mode="indeterminate">
      </mat-progress-spinner>
    </div>

    <table (matSortChange)="announceSortChange($event)"
           [dataSource]="dataSource"
           [trackBy]="trackBy"
           mat-table matSort>

      <!-- Checkbox Column -->
      <ng-container matColumnDef="actions">
        <th *matHeaderCellDef mat-header-cell>
          <div class="header-select-collapse-buttons">
            <mat-checkbox (click)="$event ? toggleAllRows() : null; "
                          [aria-label]="checkboxLabel()"
                          [checked]="selection.hasValue() && isAllSelected()"
                          [indeterminate]="selection.hasValue() && !isAllSelected()">
            </mat-checkbox>

            <ng-container *ngIf="context != contextEnum.SINGLE_PROJECT">
              <button (click)="expandAllGroups()" *ngIf="areAllGroupsCollapsed()"
                      class="small-icon-button"
                      mat-icon-button>
                <mat-icon>expand_more</mat-icon>
              </button>
              <button (click)="collapseAllGroups()" *ngIf="!areAllGroupsCollapsed()"
                      class="small-icon-button"
                      mat-icon-button>
                <mat-icon>expand_less</mat-icon>
              </button>
            </ng-container>

            <app-check-box-filter (change)="advancedFilterChanged(advancedFilter.selection, $event)"
                                  [options]="advancedFilter.selection.options"
                                  [selected]="advancedFilter.selection.selected"></app-check-box-filter>
          </div>
        </th>
        <td *matCellDef="let row" mat-cell>
          <div class="action-buttons">
            <mat-checkbox (click)="selection.toggle(row); $event.stopPropagation()"
                          [aria-label]="checkboxLabel(row)"
                          [checked]="selection.isSelected(row)">
            </mat-checkbox>

            <span>
              <mat-icon *ngIf="row.assignedProjectId"
                        [matTooltip]="assignedTooltip(row)"
                        class="small-icon"
                        fontSet="material-icons-outlined">output
              </mat-icon>
            </span>
            <span>
              <mat-icon *ngIf="row.notes?.length > 0"
                        class="small-icon"
                        fontSet="material-icons-outlined"
                        matTooltip="Has notes">notes
              </mat-icon>
            </span>
            <span>
              <mat-icon *ngIf="row.commentIds?.length > 0"
                        [matTooltip]="tooltipComment(row)"
                        class="small-icon"
                        fontSet="material-icons-outlined">comment
              </mat-icon>
            </span>

            <span [matTooltip]="tooltipAlert(row)">
              <mat-icon *ngIf="hasAlertCritical(row)" class="small-icon" color="warn">priority_high</mat-icon>
              <mat-icon *ngIf="hasAlertWarning(row)" class="yellow small-icon">question_mark</mat-icon>
            </span>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="project">
        <th *matHeaderCellDef mat-header-cell matTooltip="Project">
          <div class="flex-row">
            <span>Project</span>
            <app-check-box-filter (change)="advancedFilterChanged(advancedFilter.project, $event)"
                                  [options]="advancedFilter.project.options"
                                  [selected]="advancedFilter.project.selected"></app-check-box-filter>
          </div>
        </th>
        <td *matCellDef="let element" mat-cell> {{ element.projectName }}</td>
      </ng-container>
      <ng-container matColumnDef="projectCode">
        <th *matHeaderCellDef mat-header-cell matTooltip="Project Code">PC</th>
        <td *matCellDef="let element" mat-cell> {{ element.projectCode }}</td>
      </ng-container>
      <ng-container matColumnDef="manufacturer">
        <th *matHeaderCellDef mat-header-cell matTooltip="Manufacturer">
          <div class="flex-row">
            <span>Mfr.</span>
            <app-check-box-filter (change)="advancedFilterChanged(advancedFilter.manufacturer, $event)"
                                  [options]="advancedFilter.manufacturer.options"
                                  [selected]="advancedFilter.manufacturer.selected"></app-check-box-filter>
          </div>
        </th>
        <td *matCellDef="let element" mat-cell> {{ element.manufacturer }}</td>
      </ng-container>
      <ng-container matColumnDef="sku">
        <th *matHeaderCellDef mat-header-cell>SKU</th>
        <td *matCellDef="let element" [cdkContextMenuTriggerData]="{equipment:element}"
            [cdkContextMenuTriggerFor]="skuContext"
            mat-cell> {{ element.sku }}
        </td>
      </ng-container>
      <ng-container matColumnDef="model">
        <th *matHeaderCellDef mat-header-cell>Model</th>
        <td *matCellDef="let element" mat-cell>
          <div class="two-lines"> {{ element.model }}</div>
        </td>
      </ng-container>
      <ng-container matColumnDef="quantity">
        <th *matHeaderCellDef mat-header-cell matTooltip="Quantity">Qty.</th>
        <td *matCellDef="let element" mat-cell> {{ element.quantity }}</td>
      </ng-container>
      <ng-container matColumnDef="responsible">
        <th *matHeaderCellDef mat-header-cell>
          <div class="flex-row">
            <span>Responsible</span>
            <app-check-box-filter (change)="advancedFilterChanged(advancedFilter.responsible, $event)"
                                  [options]="advancedFilter.responsible.options"
                                  [selected]="advancedFilter.responsible.selected"
                                  [user]="true"></app-check-box-filter>
          </div>
        </th>
        <td *matCellDef="let element" mat-cell>
          <span
            *ngIf="element.responsibleByManufacturer && element.responsibleByManufacturer.length == 0 && element.responsible && element.responsible.length == 0"
            class="responsible-warning">
            <mat-icon class="small-icon" color="warn">warning</mat-icon>
            <a routerLink="/settings/manufacturer-responsibility">Assign responsible person</a>
          </span>
          <ng-container
            *ngFor="let email of (element.responsible || []).concat(element.responsibleByManufacturer); let last = last">
            <app-user [email]="email"></app-user>
            <span *ngIf="!last">, </span>
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th *matHeaderCellDef mat-header-cell>
          <div class="flex-row">
            <span>Status</span>
            <app-check-box-filter (change)="advancedFilterChanged(advancedFilter.status, $event)"
                                  [options]="advancedFilter.status.options"
                                  [selected]="advancedFilter.status.selected"></app-check-box-filter>
          </div>
        </th>
        <td *matCellDef="let element" mat-cell>
          <div [ngClass]="statusClass(element)"
               class="status">
            <app-status-history [equipment]="element">{{ element.status | readable }}</app-status-history>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="estimatedShipByDate">
        <ng-container *ngIf="context == contextEnum.SINGLE_PROJECT">
          <th *matHeaderCellDef mat-header-cell mat-sort-header
              sortActionDescription="Sort by estimated ship by date">
            Estimated ship by
          </th>
        </ng-container>
        <ng-container *ngIf="context != contextEnum.SINGLE_PROJECT">
          <th *matHeaderCellDef mat-header-cell>
            Estimated ship by
          </th>
        </ng-container>
        <td *matCellDef="let element" mat-cell>
          <ng-container *ngIf="element.estimatedShipByDate">
            {{ element.estimatedShipByDate | date:'y-MM-dd, w' }}w
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="receivedOnDate">
        <ng-container *ngIf="context == contextEnum.SINGLE_PROJECT">
          <th *matHeaderCellDef mat-header-cell mat-sort-header
              sortActionDescription="Sort by received on date">Received on
          </th>
        </ng-container>
        <ng-container *ngIf="context != contextEnum.SINGLE_PROJECT">
          <th *matHeaderCellDef mat-header-cell>Received on</th>
        </ng-container>
        <th *matHeaderCellDef mat-header-cell>Received on</th>
        <td *matCellDef="let element" mat-cell>
          <ng-container *ngIf="element.receivedOnDate">
            {{ element.receivedOnDate | date:'y-MM-dd, w' }}w
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="diffFromContract">
        <th *matHeaderCellDef mat-header-cell matTooltip="Different From original Contract">DFC</th>
        <td *matCellDef="let element" mat-cell>
          <div class="vertical-align-icon-and-text">
            <ng-container *ngIf="element.diffFromContract == diffFromContractEnum.YES_NEEDS_ACTION">
              <mat-icon color="warn" matTooltip="Yes, needs action!">warning</mat-icon>
            </ng-container>
            <ng-container *ngIf="element.diffFromContract == diffFromContractEnum.YES_RESOLVED">
              <mat-icon color="primary" matTooltip="Yes, resolved">checkmark</mat-icon>
            </ng-container>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="inStock">
        <th *matHeaderCellDef mat-header-cell matTooltip="In Stock count">
          <div class="flex-row">
            <span>In Stock</span>
            <app-check-box-filter (change)="advancedFilterChanged(advancedFilter.inStock, $event)"
                                  [options]="advancedFilter.inStock.options"
                                  [selected]="advancedFilter.inStock.selected"></app-check-box-filter>
          </div>
        </th>
        <td *matCellDef="let element" class="item-status" mat-cell>
          <div [ngClass]="itemStatusClass(ItemStatus.IN_STOCK, element)"
               class="status">{{ element.itemCountByStatus[ItemStatus.IN_STOCK] }}/{{ element.quantity }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="pdiDone">
        <th *matHeaderCellDef mat-header-cell matTooltip="Pre-Delivery Inspection completion count">
          <div class="flex-row">
            <span>PDI</span>
            <app-check-box-filter (change)="advancedFilterChanged(advancedFilter.pdiDone, $event)"
                                  [options]="advancedFilter.pdiDone.options"
                                  [selected]="advancedFilter.pdiDone.selected"></app-check-box-filter>
          </div>
        </th>
        <td *matCellDef="let element" class="item-status" mat-cell>
          <div [ngClass]="itemStatusClass(ItemStatus.PDI_DONE, element)"
               class="status">{{ element.itemCountByStatus[ItemStatus.PDI_DONE] }}/{{ element.quantity }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="readyToShip">
        <th *matHeaderCellDef mat-header-cell matTooltip="Ready To Ship count">
          <div class="flex-row">
            <span>Ready To Ship</span>
            <app-check-box-filter (change)="advancedFilterChanged(advancedFilter.readyToShip, $event)"
                                  [options]="advancedFilter.readyToShip.options"
                                  [selected]="advancedFilter.readyToShip.selected"></app-check-box-filter>
          </div>
        </th>
        <td *matCellDef="let element" class="item-status" mat-cell>
          <div [ngClass]="itemStatusClass(ItemStatus.READY_TO_SHIP, element)"
               class="status">{{ element.itemCountByStatus[ItemStatus.READY_TO_SHIP] }}/{{ element.quantity }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="onClientPremises">
        <th *matHeaderCellDef mat-header-cell matTooltip="On Client Premises count">
          <div class="flex-row">
            <span>On Client Premises</span>
            <app-check-box-filter (change)="advancedFilterChanged(advancedFilter.onClientPremises, $event)"
                                  [options]="advancedFilter.onClientPremises.options"
                                  [selected]="advancedFilter.onClientPremises.selected"></app-check-box-filter>
          </div>
        </th>
        <td *matCellDef="let element" class="item-status" mat-cell>
          <div [ngClass]="itemStatusClass(ItemStatus.ON_CLIENT_PREMISES, element)"
               class="status">{{ element.itemCountByStatus[ItemStatus.ON_CLIENT_PREMISES] }}/{{ element.quantity }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="installed">
        <th *matHeaderCellDef mat-header-cell matTooltip="Installed count">
          <div class="flex-row">
            <span>Installed</span>
            <app-check-box-filter (change)="advancedFilterChanged(advancedFilter.installed, $event)"
                                  [options]="advancedFilter.installed.options"
                                  [selected]="advancedFilter.installed.selected"></app-check-box-filter>
          </div>
        </th>
        <td *matCellDef="let element" class="item-status" mat-cell>
          <div [ngClass]="itemStatusClass(ItemStatus.INSTALLED, element)"
               class="status">{{ element.itemCountByStatus[ItemStatus.INSTALLED] }}/{{ element.quantity }}
          </div>
        </td>
      </ng-container>


      <ng-container matColumnDef="serialNumbers">
        <th *matHeaderCellDef mat-header-cell>
          Serial Numbers
        </th>
        <td *matCellDef="let element" mat-cell>
          <div *ngFor="let i of element.items" class="flex">
            <span>{{ i.serialNumber }}</span>
            <mat-icon *ngIf="i.pdiDone"
                      class="very-small-icon" color="primary" matTooltip="PDI Done">checkmark
            </mat-icon>
            <span *ngIf="i.firmwareVersion">&nbsp;FW {{ i.firmwareVersion }}</span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="timeline">
        <th *matHeaderCellDef mat-header-cell>
          <div class="headline">
            <mat-button-toggle (change)="$event.source.checked = false" (click)="goLeft()"
                               matTooltip="earlier">
              <mat-icon class="small-icon">arrow_back</mat-icon>
            </mat-button-toggle>

            <mat-button-toggle-group [(value)]="level" aria-label="Font Style" name="fontStyle">
              <mat-button-toggle [value]="levelEnum.DAY">Day</mat-button-toggle>
              <mat-button-toggle [value]="levelEnum.WEEK">Week</mat-button-toggle>
              <mat-button-toggle [value]="levelEnum.MONTH">Month</mat-button-toggle>
            </mat-button-toggle-group>

            <mat-button-toggle (change)="$event.source.checked = false" (click)="goRight()"
                               matTooltip="later">
              <mat-icon class="small-icon">arrow_forward</mat-icon>
            </mat-button-toggle>

            <mat-button-toggle (change)="$event.source.checked = false" (click)="jumpToToday()"
                               matTooltip="jump to today">
              Today
            </mat-button-toggle>
          </div>
          <div class="header-units">
            <span (click)="goDown(i)" *ngFor="let i of arrayOf12" [matTooltip]="timelineTooltip(i)"
                  [ngClass]="timelineUnitClass(i)"
                  class="unit">{{ timelineTitle(i) }}</span>
          </div>
        </th>
        <td *matCellDef="let element" mat-cell>
          <div class="row-units">
            <span (click)="goDown(i)" *ngFor="let i of arrayOf12" [matTooltip]="timelineTooltip(i, element)"
                  [ngClass]="timelineUnitClass(i, element)"
                  class="unit">{{ timelineCell(i, element) }}</span>
          </div>
        </td>
      </ng-container>

      <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
      <tr (click)="openEditDialog(element)" *matRowDef="let element; columns: displayedColumns; when: isNotGroup"
          [class.changed-from-contract]="element.diffFromContract == diffFromContractEnum.YES_RESOLVED"
          [class.group-not-visible]="!isGroupVisible(element)"
          class="equipment-row"
          mat-row></tr>

      <!-- Row shown when there is no matching data. -->
      <tr *matNoDataRow class="mat-row">
        <td class="mat-cell no-equipment" colspan="4">
          <div *ngIf="!filterLoading">
            <p>No equipment found</p>
            <p *ngIf="loadedByFilter.length > 0">Text filter "{{ loadedByFilter }}"
              <button (click)="clearFilter()" mat-button>Clear</button>
            </p>

            <p *ngIf="advancedFilter.project.selected.length > 0">
              Projects filter:
              <span *ngFor="let o of advancedFilter.project.options">
                <span *ngIf="advancedFilter.project.selected.includes(o.value)">
                  {{ o.display }};
                </span>
              </span>
              <button (click)="advancedFilterChanged(advancedFilter.project, [])" mat-button>Clear
              </button>
            </p>

            <p *ngIf="advancedFilter.manufacturer.selected.length > 0">
              Manufacturer filter:
              <span *ngFor="let o of advancedFilter.manufacturer.options">
                <span *ngIf="advancedFilter.manufacturer.selected.includes(o.value)">
                  {{ o.display }};
                </span>
              </span>
              <button (click)="advancedFilterChanged(advancedFilter.manufacturer, [])" mat-button>Clear
              </button>
            </p>

            <p *ngIf="advancedFilter.responsible.selected.length > 0">
              Responsible filter:
              <span *ngFor="let o of advancedFilter.responsible.options">
                <span *ngIf="advancedFilter.responsible.selected.includes(o.value)">
                  {{ o.display }};
                </span>
              </span>
              <button (click)="advancedFilterChanged(advancedFilter.responsible, [])" mat-button>Clear
              </button>
            </p>

            <p *ngIf="advancedFilter.status.selected.length > 0">
              Status filter:
              <span *ngFor="let o of advancedFilter.status.options">
                <span *ngIf="advancedFilter.status.selected.includes(o.value)">
                  {{ o.display }};
                </span>
              </span>
              <button (click)="advancedFilterChanged(advancedFilter.status, [])" mat-button>Clear</button>
            </p>

            <p *ngIf="advancedFilter.pdiDone.selected.length > 0">
              PDI Done filter:
              <span *ngFor="let o of advancedFilter.pdiDone.options">
                <span *ngIf="advancedFilter.pdiDone.selected.includes(o.value)">
                  {{ o.display }};
                </span>
              </span>
              <button (click)="advancedFilterChanged(advancedFilter.pdiDone, [])" mat-button>Clear</button>
            </p>
          </div>
        </td>
      </tr>

      <!-- Group header -->
      <ng-container matColumnDef="groupHeader">
        <td (click)="toggleGroup(group)" *matCellDef="let group" class="group-cell" colspan="999">
          <div class="group-cell-content">
            <button class="small-icon-button" mat-icon-button>
              <mat-icon *ngIf="group.expanded">expand_less</mat-icon>
              <mat-icon *ngIf="!group.expanded">expand_more</mat-icon>
            </button>
            <strong *ngIf="context == contextEnum.ACCOUNTING_ID">group
              <a (click)="$event.stopPropagation()"
                 [routerLink]="group.project | url">{{ group.project.name }}</a>
            </strong>
            <strong *ngIf="context == contextEnum.ALL_EQUIPMENT">group
              <a (click)="$event.stopPropagation()"
                 [routerLink]="group.project | url">{{ group.project.name }}</a>
            </strong>
            <app-project-status [condense]="true" [project]="group.project"></app-project-status>
          </div>
        </td>
      </ng-container>

      <tr *matRowDef="let row; columns: ['groupHeader']; when: isGroup" class="group-row" mat-row></tr>

    </table>
  </section>
</div>
