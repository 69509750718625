import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {Task, TaskCreateRequest} from "../../api/model/task";
import {TaskService} from "../../api/task.service";
import {BelongsTo} from "../../api/model/common";
import {NgModel} from "@angular/forms";

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class TasksComponent implements OnInit, OnChanges {
  constructor(private taskService: TaskService) {}

  @Input() forId!: string;

  tasks: Task[] | undefined;
  addTaskField: string = "";

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.reload()
  }

  reload() {
    this.taskService.list(this.forId).subscribe(t => this.tasks = t)
  }

  updateAllComplete(task: Task) {
    task.complete = task.subtasks != null && task.subtasks.every(t => t.complete);
    this.saveTask(task);
  }

  someComplete(task: Task): boolean {
    if (task.subtasks == null) {
      return false;
    }
    return task.subtasks.filter(t => t.complete).length > 0 && !task.complete;
  }

  setAll(task: Task, completed: boolean) {
    task.complete = completed;
    if (task.subtasks == null) {
      return;
    }
    task.subtasks.forEach(t => (t.complete = completed));
    this.saveTask(task);
  }

  addTask(taskAdd: NgModel) {
    const request: TaskCreateRequest = {
      belongsToId: this.forId,
      belongsTo: BelongsTo.PROJECT,
      text: taskAdd.value
    };
    this.taskService.create(request)
      .subscribe(task => {
        this.tasks?.push(task);
        taskAdd.reset();
      });
  }

  addSubtask(task: Task, element: HTMLTextAreaElement) {
    task.subtasks.push({
      text: element.value || "",
      complete: false
    });
    element.value = "";
    element.setAttribute("dirty", "true")
    this.updateAllComplete(task);
  }

  saveTask(task: Task, field?: NgModel) {
    this.taskService.update(task.id, task).subscribe(
      value => {
        console.log("task saved", value, field)
        field?.control.markAsPristine()
      }
    );
  }

  deleteTask(task: Task) {
    if (window.confirm("Do you really want to delete this task?")) {
      this.taskService.delete(task.id).subscribe(
        value => this.reload()
      )
    }
  }
  deleteSubtask(task: Task, index: number) {
    if (window.confirm("Do you really want to delete this subtask?")) {
      task.subtasks.splice(index, 1)
      this.saveTask(task)
    }
  }
}
