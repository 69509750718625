import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {Manufacturer, ManufacturerCreateUpdateRequest} from "./model/manufacturer";

@Injectable()
export class ManufacturerService {
  constructor(private http: HttpClient) {
  }

  list(): Observable<Manufacturer[]> {
    return this.http.get<Manufacturer[]>(environment.apiUrl + "/equipment/manufacturer")
  }

  listUnassigned(): Observable<String[]> {
    return this.http.get<String[]>(environment.apiUrl + "/equipment/manufacturer/unassigned")
  }

  update(request: ManufacturerCreateUpdateRequest): Observable<any> {
    return this.http.put(environment.apiUrl + "/equipment/manufacturer", request)
  }

  delete(id: string): Observable<any> {
    return this.http.delete(environment.apiUrl + "/equipment/manufacturer/" + id)
  }
}
