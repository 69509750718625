<mat-sidenav-container *ngIf="projects" class="sidenav-container">
  <mat-sidenav #sidenav class="sidenav" mode="side" opened>
    <app-project-list (updated)="onUpdate()" [alerts]="alerts" [editable]="true" [expanded]="true"
                      [projects]="projects" [showDeadline]="false"></app-project-list>
    <div class="footer">
      <button (click)="openAddTopLevelProjectDialog()" mat-button>
        <mat-icon>add</mat-icon>
        Add top-level project
      </button>
    </div>
  </mat-sidenav>
  <mat-sidenav-content class="sidenav-content">
    <div class="toggle-button-container">
      <button (click)="sidenav.toggle()" aria-label="Collapse/expand" class="toggle-button" color="primary"
              mat-mini-fab>
        <mat-icon *ngIf="sidenav.opened">menu_open</mat-icon>
        <mat-icon *ngIf="!sidenav.opened">menu</mat-icon>
      </button>
    </div>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>

<ng-container *ngIf="projects === undefined">
  Loading projects...
  <!-- TODO prettify loading screens -->
</ng-container>
