import {Component, HostListener, Inject} from '@angular/core';
import {Project} from "../../api/model/project";
import {EMPTY, Observable} from "rxjs";
import {ProjectService} from "../../api/project.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {apiErrorToMessage} from "../../util";

@Component({
  selector: 'app-add-child',
  templateUrl: './add-child-dialog.component.html',
  styleUrls: ['../add-edit-dialog/project-add-edit-dialog.component.sass', './add-child-dialog.component.sass']
})
export class AddChildDialogComponent {
  parent: Project | undefined;
  apiError: string | undefined;
  created: Project | undefined;
  addEditForm = new FormGroup({
    name: new FormControl("", [Validators.required]),
  });

  constructor(
    private projectService: ProjectService,
    public dialogRef: MatDialogRef<AddChildDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ProjectAddChildDialogData,
  ) {
    this.parent = data.parent;
  }

  @HostListener('window:keyup.esc') onKeyUp() {
    this.dialogRef.close();
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return !this.addEditForm.dirty;
  }

  onCancelClick() {
    this.dialogRef.close();
  }

  onSubmit() {
    this.apiError = undefined;

    const name = this.addEditForm.controls.name.value;
    if (this.parent && name) {
      this.projectService.create({
        accountingId: this.parent.accountingId,
        name: name,
        parentId: this.parent.id,
      })
        .subscribe({
          next: (project) => {
            console.log("AddChildDialogComponent created child project", project);
            this.created = project;
          },
          error: error => {
            console.log("dialog failed to add/edit", error)
            this.apiError = apiErrorToMessage(error);
            return EMPTY
          },
          complete: () => {
            if (!this.apiError) {
              this.dialogRef.close(this.created || true)
            }
          }
        });
    }
  }
}

export interface ProjectAddChildDialogData {
  parent: Project | undefined;
}
