import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {DashComponent} from "./dash/dash.component";
import {LoginComponent} from "./auth/login/login.component";
import {PageNotFoundComponent} from "./page-not-found/page-not-found.component";
import {authGuard} from "./auth/auth.guard";
import {EquipmentCatalogComponent} from "./settings/equipment-catalog/equipment-catalog.component";
import {ManufacturerComponent} from "./settings/manufacturer/manufacturer.component";
import {ProjectListViewComponent} from "./project/list-view/project-list-view.component";
import {AccountingIdWrapComponent} from "./project/accounting-id-wrap/accounting-id-wrap.component";
import {accountingIdResolver} from "./project/accounting-id-resolver";
import {EquipmentTableComponent} from "./equipment/equipment-table/equipment-table.component";
import {projectResolver} from "./project/project-resolver";
import {ProjectType} from "./api/model/project";
import {UsersComponent} from "./settings/users/users.component";
import {RegisterComponent} from "./auth/register/register.component";
import {PasswordResetComponent} from "./auth/password-reset/password-reset.component";
import {UserProfileComponent} from "./settings/user-profile/user-profile.component";
import {ProjectImportComponent} from "./settings/project-import/project-import.component";
import {RmaListComponent} from "./service/rma-list/rma-list.component";
import {SingleRmaComponent} from "./service/single-rma/single-rma.component";
import {rmaResolver} from "./service/rma-resolver";
import {pendingChangesGuard} from "./pending-changes.guard";

import {EquipmentGroup} from "./api/model/equipment";

function projectChildrenPaths(type: ProjectType): Routes {
  return [
    {
      path: 'list',
      component: ProjectListViewComponent,
      data: {type: type, breadcrumb: undefined}
    },
    {
      path: ':accountingId',
      component: AccountingIdWrapComponent,
      runGuardsAndResolvers: 'always',
      resolve: {projects: accountingIdResolver},
      data: {breadcrumb: (data: any) => data.projects[0].accountingId},
      children: [
        {
          path: 'equipment', component: EquipmentTableComponent,
          canDeactivate: [pendingChangesGuard],
        },
        {
          path: ':projectId', component: EquipmentTableComponent, resolve: {project: projectResolver},
          canDeactivate: [pendingChangesGuard],
          runGuardsAndResolvers: 'always',
          data: {
            breadcrumb: (data: any) => {
              return {project: data.project};
            }
          },
        },
        {path: '', redirectTo: 'equipment', pathMatch: 'full'},
      ]
    },
    {path: '', redirectTo: 'list', pathMatch: 'full'},
  ];
}

const routes: Routes = [
  {
    path: '', canActivateChild: [authGuard],
    children: [
      {path: 'project', children: projectChildrenPaths(ProjectType.PUBLIC), data: {breadcrumb: "Projects"}},
      {path: 'production', children: projectChildrenPaths(ProjectType.PRODUCTION), data: {breadcrumb: "Production"}},
      {path: 'sales', children: projectChildrenPaths(ProjectType.SALES), data: {breadcrumb: "Sales"}},
      {
        path: 'equipment', component: EquipmentTableComponent,
        canDeactivate: [pendingChangesGuard], data: {breadcrumb: "Equipment"}
      },
      {
        path: 'service', data: {breadcrumb: "Service"}, children: [
          {
            path: ':rmaNumber',
            component: SingleRmaComponent,
            resolve: {rma: rmaResolver},
            canDeactivate: [pendingChangesGuard],
            data: {breadcrumb: (data: any) => data.rma.rmaNumber},
          },
          {path: '', component: RmaListComponent},
        ]
      },
      {
        path: 'settings', data: {breadcrumb: "Settings"}, children: [
          {path: 'project-import', component: ProjectImportComponent, data: {breadcrumb: "Project Import"}},
          {
            path: 'equipment-catalog',
            component: EquipmentCatalogComponent,
            data: {breadcrumb: "Equipment Catalog", group: EquipmentGroup.PROJECT}
          },
          {
            path: 'spare-parts-catalog',
            component: EquipmentCatalogComponent,
            data: {breadcrumb: "Service Spare Parts Catalog", group: EquipmentGroup.SERVICE}
          },
          {path: 'user-profile', component: UserProfileComponent, data: {breadcrumb: "User Profile"}},
          {path: 'users', component: UsersComponent, data: {breadcrumb: "Users"}},
          {
            path: 'manufacturer-responsibility',
            component: ManufacturerComponent,
            data: {breadcrumb: "Manufacturer Responsibility"}
          },
          {path: '', redirectTo: '/dashboard', pathMatch: 'full'},
        ]
      },
      {path: 'dashboard', component: DashComponent, data: {breadcrumb: "Dashboard"}},
      {path: '', redirectTo: '/dashboard', pathMatch: 'full'},
    ],
  },
  {path: 'login', component: LoginComponent, data: {breadcrumb: "Login"}},
  {path: 'register', component: RegisterComponent, data: {breadcrumb: "Registration"}},
  {path: 'password-reset', component: PasswordResetComponent, data: {breadcrumb: "Password Reset"}},
  {path: '**', component: PageNotFoundComponent},
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload',
    preloadingStrategy: PreloadAllModules, // <-- debugging purposes only
  })],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
