import {Authored} from "./common";

export interface UserInfo {
  id: string;
  name: string;
  email: string;
  roles: string[];
  lastLogin: number;
  created: Authored;
  updated: Authored;
  bookmarkedProjects: string[];
}

export enum UserRole {
  ADMIN = "ADMIN",
  READ_ALL = "READ_ALL",
  WRITE_ALL = "WRITE_ALL",
  LOGISTICS = "LOGISTICS",
  WAREHOUSE = "WAREHOUSE",
  SERVICE = "SERVICE",
  ADD_PROJECT = "ADD_PROJECT",
  ADD_SALES_PROJECT = "ADD_SALES_PROJECT",
}

export interface UserShortResponse {
  id: string;
  name: string;
  email: string;
  roles: string[];
}

export interface LoginRequest {
  email: string;
  password: string;
}

export interface LoginResponse {
  user: UserInfo,
  token: string;
}

export interface UserCreateUpdateRequest {
  email?: string | undefined,
  name?: string | undefined,
  roles?: string[] | undefined,
  oldPassword?: string | undefined,
  newPassword?: string | undefined,
}

export interface UserRegistrationRequest {
  emailLinkId: string;
  name: string;
  password: string;
}

export interface PasswordResetRequest {
  emailLinkId: string;
  password: string;
}
