import {Component, Input} from '@angular/core';
import {AuthService} from "../../api/auth.service";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

@Component({
  selector: 'app-user',
  templateUrl: './single-user.component.html',
  styleUrls: ['./single-user.component.sass']
})
export class SingleUserComponent {
  @Input("email") email!: string;
  name: Observable<string>

  constructor(authService: AuthService) {
    this.name = authService.getAllUsers().pipe(
      map(users => users.find(u => u.email === this.email)?.name || this.email)
    );
    this.name.subscribe(value => console.log(`app-user loaded name ${value} for email ${this.email}`));
  }
}
