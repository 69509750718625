<h1 mat-dialog-title>
  {{ verb }} user <span *ngIf="data.user">
    <app-user [email]="data.user.email"></app-user>
  </span>
</h1>

<div class="dense-1" mat-dialog-content>
  <form (ngSubmit)="onSubmit()" [formGroup]="addEditForm" class="add-edit-user-form">

    <div>
      <mat-form-field class="no-bottom email">
        <mat-label>Email</mat-label>
        <input formControlName="email" matInput placeholder="Ex. sales@sonus.lt" type="text">
      </mat-form-field>
    </div>

    <div>
      <mat-form-field class="no-bottom name">
        <mat-label>Name</mat-label>
        <input formControlName="name" matInput placeholder="Ex. Jonas" type="text">
      </mat-form-field>
    </div>

    <div>
      <mat-form-field class="no-bottom status">
        <mat-label>Roles</mat-label>
        <mat-select formControlName="roles" multiple>
          <mat-option *ngFor="let role of userRoles" [value]="role">{{role}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

  </form>
</div>
<div mat-dialog-actions>
  <button (click)="onCancelClick()" [color]="addEditForm.dirty ? 'warn' : ''"
          mat-button>Cancel
  </button>
  <button (click)="onPrimaryClick()" [disabled]="!addEditForm.valid" color="primary" mat-raised-button>
    {{ verb }}
  </button>
  <button (click)="resetPassword()" *ngIf="data.user" [disabled]="hasResetPassword" color="accent" mat-button>
    Send password reset link
  </button>
  <mat-error *ngIf="apiError" class="api-error">
    {{ apiError }}
  </mat-error>
</div>
