<h1 mat-dialog-title>
  New user registration
</h1>

<form (ngSubmit)="onSubmit()" *ngIf="user" [formGroup]="registrationForm" class="registration-form">
  <p>Hey there! You have been invited to Sonus Space via your email address {{user.email}}.</p>
  <p>Set your name and password below and login to start using this system.</p>

  <div>
    <mat-form-field class="email">
      <mat-label>Email</mat-label>
      <input [value]="user.email" disabled="true" matInput type="text">
    </mat-form-field>
  </div>

  <div>
    <mat-form-field class="name">
      <mat-label>Name</mat-label>
      <input formControlName="name" matInput placeholder="Ex. Jonas" type="text">
    </mat-form-field>
  </div>

  <div>
    <mat-form-field class="password">
      <mat-label>Password</mat-label>
      <input formControlName="password" matInput placeholder="Ex. sales@sonus.lt" type="password">
    </mat-form-field>
  </div>

  <button [disabled]="registrationForm.invalid" color="primary" mat-raised-button type="submit">Register</button>
</form>

<mat-error *ngIf="apiError" class="api-error">
  {{ apiError }}
</mat-error>
