import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-project-deadline',
  templateUrl: './deadline.component.html',
  styleUrls: ['./deadline.component.sass']
})
export class DeadlineComponent {
  @Input() deadline: number | undefined;
}
