import {ProjectType} from "./project";

export interface Alert {
  level: AlertLevel;
  project: ProjectInfo;
  culpritEquipmentIds: string[];
  message: string;
}

export interface ProjectInfo {
  id: string;
  type: ProjectType;
  name: string;
  accountingId: string;
}

export enum AlertLevel {
  CRITICAL = "CRITICAL",
  WARNING = "WARNING",
}
