<div class="button-header">
  <button (click)="openInviteUserDialog()"
          color="primary"
          mat-raised-button>
    <mat-icon>person_add</mat-icon>
    Invite
  </button>
</div>

<table [dataSource]="users" class="users-table mat-elevation-z8" mat-table>

  <ng-container matColumnDef="name">
    <th *matHeaderCellDef mat-header-cell>Name</th>
    <td *matCellDef="let element" mat-cell>{{element.name}}</td>
  </ng-container>

  <ng-container matColumnDef="email">
    <th *matHeaderCellDef mat-header-cell>Email</th>
    <td *matCellDef="let element" mat-cell>{{element.email}}</td>
  </ng-container>

  <ng-container matColumnDef="roles">
    <th *matHeaderCellDef mat-header-cell>Roles</th>
    <td *matCellDef="let element" mat-cell>{{element.roles.join(', ')}}</td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th *matHeaderCellDef mat-header-cell></th>
    <td *matCellDef="let element" mat-cell>
      <button (click)="edit(element)" class="small-icon-button" mat-icon-button>
        <mat-icon class="small-icon" matPrefix>edit</mat-icon>
      </button>
      <button (click)="delete(element)" class="small-icon-button" mat-icon-button>
        <mat-icon class="small-icon" matPrefix>delete</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
  <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
</table>
