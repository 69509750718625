export enum BelongsTo {
  PROJECT = "PROJECT",
  EQUIPMENT = "EQUIPMENT",
}

export interface Authored {
  at: Date;
  by: string;
}

export interface Health {
  status: string;
}

export interface ApiError {
  message: string;
  exceptionClass: string;
}
