import {Component, Input} from '@angular/core';
import {Equipment} from "../../api/model/equipment";

@Component({
    selector: 'app-status-history',
    templateUrl: './status-history.component.html',
    styleUrls: ['./status-history.component.sass']
})
export class StatusHistoryComponent {
    @Input() equipment!: Equipment;
    isOpen = false;
}
