<div *ngIf="project !== undefined" class="project-main-wrapper">
  <div class="row">
    <div class="aligned bookmark">
      <a (click)="toggleProjectBookmark()">
        <mat-icon *ngIf="!bookmarked">bookmark_outlined</mat-icon>
        <mat-icon *ngIf="bookmarked">bookmark</mat-icon>
      </a>
    </div>
    <div class="aligned project-name">
      <h2 class="mat-h2 row">Project {{ project.accountingId }} {{ project.name }}</h2>
      <h4>Deadline: {{ project.details.deadline | date:'y-MM-dd' }}</h4>
    </div>
    <div class="aligned">
      <button (click)="editProjectName()" class="edit-button" mat-icon-button
              matTooltip="Change name, details or description">
        <mat-icon>edit</mat-icon>
      </button>
    </div>
    <div class="aligned status-and-warning">
      <app-project-status [project]="project"></app-project-status>
    </div>
    <div class="aligned">
      <button [matMenuTriggerFor]="changeStatusMenu" class="edit-button" mat-icon-button matTooltip="Change status">
        <mat-icon>edit</mat-icon>
      </button>
      <mat-menu #changeStatusMenu="matMenu" class="change-status-menu">
        <button (click)="changeStatus(s)" *ngFor="let s of statuses" mat-menu-item>{{ s }}</button>
      </mat-menu>
    </div>
  </div>

  <nav [tabPanel]="tabPanel" mat-stretch-tabs="false" mat-tab-nav-bar>
    <a [active]="activeTabIndex == 0" mat-tab-link routerLink="overview">
      <mat-icon class="tab-icon">dashboard</mat-icon>
      Overview
    </a>
    <a [active]="activeTabIndex == 1" mat-tab-link routerLink="equipment">
      <mat-icon class="tab-icon">view_list</mat-icon>
      Equipment
    </a>
    <a [active]="activeTabIndex == 2" mat-tab-link routerLink="supply-timeline">
      <mat-icon class="tab-icon">calendar_month</mat-icon>
      Supply Timeline
    </a>
  </nav>
  <mat-tab-nav-panel #tabPanel>
    <router-outlet></router-outlet>
  </mat-tab-nav-panel>
</div>

<ng-container *ngIf="project === undefined">
  Loading project...
  <!-- TODO prettify loading screens -->
</ng-container>
