import {Component} from '@angular/core';
import {AuthService} from "../../api/auth.service";
import {ActivatedRoute, Router} from "@angular/router";
import {UserRegistrationRequest, UserShortResponse} from "../../api/model/user";
import {HttpErrorResponse} from "@angular/common/http";
import {ApiError} from "../../api/model/common";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {EMPTY} from "rxjs";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.sass']
})
export class RegisterComponent {
  user: UserShortResponse | undefined;
  registrationForm = new FormGroup({
    name: new FormControl("", [Validators.required]),
    password: new FormControl("", [Validators.required, Validators.minLength(5)]),
  })
  apiError: string | undefined;
  private emailLinkId: string = "";

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private _snackBar: MatSnackBar,
  ) {
    route.queryParams.subscribe(params => {
      this.emailLinkId = params["id"]
      console.log("RegisterComponent emailLinkId", this.emailLinkId)
      authService.getUserByEmailLink(this.emailLinkId).subscribe({
        next: user => {
          this.user = user;
          this.registrationForm.controls.name.setValue(user.name);
        },
        error: error => this.apiError = ((error as HttpErrorResponse).error as ApiError).message || (error as HttpErrorResponse).message,
      })
    })
  }

  onSubmit() {
    if (this.registrationForm.valid) {
      this.apiError = undefined;
      const formData = this.registrationForm.getRawValue();

      const request = formData as UserRegistrationRequest;
      request.emailLinkId = this.emailLinkId;

      this.authService.register(request)
        .subscribe({
          next: (user) => {
            console.log("RegisterComponent registered user", user);
            this._snackBar.open(`Congrats, ${user.name}! Your registration is complete. You can now login.`,
              undefined, {duration: 10000});
            this.router.navigate(["/"]);
          },
          error: error => {
            console.log("RegisterComponent failed to register", error)
            this.apiError = ((error as HttpErrorResponse).error as ApiError).message || (error as HttpErrorResponse).message;
            return EMPTY;
          }
        });
    }
  }
}
