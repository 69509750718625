<div [cdkContextMenuTriggerFor]="editable ? contextMenu : null" [ngClass]="project.status" class="single-group">
  <span (click)="toggleProjectBookmark(project)" class="detail-bit bookmark">
    <a>
      <mat-icon *ngIf="!project.bookmarked" class="small-icon">bookmark_outlined</mat-icon>
      <mat-icon *ngIf="project.bookmarked" class="small-icon">bookmark</mat-icon>
    </a>
  </span>
  <app-project-status [condense]="true" [project]="project"></app-project-status>

  <a [routerLink]="project | url" [title]="project.nameWithoutParents" class="link" mat-button
     routerLinkActive="active">
    <ng-container *ngIf="project.parentId else topProject">
      {{ project.nameWithoutParents }}
    </ng-container>
    <ng-template #topProject>
      {{ project.name }}
    </ng-template>
  </a>

  <app-alert-icon [alerts]="alerts" [project]="project"></app-alert-icon>

  <app-project-deadline *ngIf="showDeadline" [deadline]="project.details.deadline"></app-project-deadline>

  <mat-icon *ngIf="project.locked" class="small-icon" fontSet="material-icons-outlined" matTooltip="Project is locked">
    lock
  </mat-icon>

  <ng-container *ngIf="editable">
    <button [matMenuTriggerFor]="additionalActionsMenu" class="small-icon-button" mat-icon-button
            matSuffix
            matTooltip="Additional actions for this project group. You can also right-click to see the same menu">
      <mat-icon>more_vert</mat-icon>
    </button>

    <mat-menu #additionalActionsMenu="matMenu">
      <ng-container *ngTemplateOutlet="contextMenuContent"></ng-container>
    </mat-menu>
  </ng-container>

  <ng-template #contextMenu>
    <div cdkMenu
         class="mat-mdc-menu-panel mat-mdc-menu-content mat-elevation-z8">
      <ng-container *ngTemplateOutlet="contextMenuContent"></ng-container>
    </div>
  </ng-template>

  <ng-template #contextMenuContent>
    <button (click)="toggleProjectLock(project)" cdkMenuItem class="edit-button" mat-menu-item>
      <mat-icon *ngIf="project.locked">lock_open</mat-icon>
      <mat-icon *ngIf="!project.locked">lock</mat-icon>
      <ng-container *ngIf="project.locked">Unlock</ng-container>
      <ng-container *ngIf="!project.locked">Lock</ng-container>
    </button>
    <button (click)="editProject(project)" cdkMenuItem class="edit-button" mat-menu-item>
      <mat-icon>visibility</mat-icon>
      Details
    </button>
    <button (click)="addChild(project)" cdkMenuItem class="add-child-button" mat-menu-item>
      <mat-icon>add</mat-icon>
      Add child
    </button>
    <button (click)="editPermissions(project)" cdkMenuItem class="add-child-button" mat-menu-item>
      <mat-icon>supervisor_account</mat-icon>
      Permissions
    </button>
  </ng-template>
</div>
