import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {FormControl} from "@angular/forms";
import {MatChipInputEvent} from "@angular/material/chips";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {UserShortResponse} from "../../api/model/user";
import {AuthService} from "../../api/auth.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {COMMA, ENTER} from "@angular/cdk/keycodes";

@Component({
  selector: 'app-user-field',
  templateUrl: './user-field.component.html',
  styleUrls: ['./user-field.component.sass']
})
export class UserFieldComponent implements OnInit, AfterViewInit {
  @Input() control!: FormControl<string [] | null>;
  @Input() hint: string | undefined;
  @Input() hintTooltip: string = '';
  @Input() required: boolean = false;
  @ViewChild('responsibleInput') responsibleInput!: ElementRef<HTMLInputElement>;

  allUsers: UserShortResponse[] = []
  separatorKeysCodes: number[] = [COMMA, ENTER];
  filteredUsers: UserShortResponse[] = [];

  constructor(
    private authService: AuthService,
    private _snackBar: MatSnackBar,
  ) {
  }

  ngOnInit() {
    this.authService.getAllUsers().subscribe(
      users => {
        this.allUsers = users;
        this._filter("");
      }
    );
  }

  ngAfterViewInit() {
    this.responsibleInput.nativeElement.onkeyup = _ => {
      this._filter(this.responsibleInput.nativeElement.value || "");
    };
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (value) {
      this._snackBar.open('Pick a person from the dropdown',
        undefined, {duration: 5000});
    }

    // Clear the input value
    event.chipInput!.clear();
    this.responsibleInput.nativeElement.value = "";
    this._filter("");
  }

  remove(responsible: string): void {
    if (this.control.value) {
      const index = this.control.value.indexOf(responsible);

      if (index >= 0) {
        this.control.setValue(this.control.value?.filter(i => i !== responsible));
        this.control.markAsDirty();
        this._filter("");
      }
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const selected = this.control.value?.slice() || []
    selected.push(event.option.value);
    this.control.setValue(selected);
    this.control.markAsDirty();
    this.responsibleInput.nativeElement.value = '';
    this._filter("");
  }

  private _filter(value: string) {
    const filterValue = value.toLowerCase();

    this.filteredUsers = this.allUsers
      .filter(u => u.name.toLowerCase().includes(filterValue) || u.email.toLowerCase().includes(filterValue))
      .filter(u => !this.control.value || this.control.value.indexOf(u.email) < 0);
  }
}
