<h1 mat-dialog-title>
  Reset your password
</h1>

<form (ngSubmit)="onSubmit()" *ngIf="user" [formGroup]="passwordResetForm" class="password-reset-form">
  <p>Hey there, {{user.name}}! Seems you have forgotten your password to Sonus Space.</p>
  <p>Best password is one you don't have to remember, so consider using a password manager like
    <a href="https://1password.com/" target="_blank">1Password</a>.</p>

  <div>
    <mat-form-field class="email">
      <mat-label>Email</mat-label>
      <input [value]="user.email" disabled="true" matInput type="text">
    </mat-form-field>
  </div>

  <div>
    <mat-form-field class="password">
      <mat-label>Password</mat-label>
      <input formControlName="password" matInput placeholder="Ex. sales@sonus.lt" type="password">
    </mat-form-field>
  </div>

  <button [disabled]="passwordResetForm.invalid" color="primary" mat-raised-button type="submit">Register</button>
</form>

<mat-error *ngIf="apiError" class="api-error">
  {{ apiError }}
</mat-error>
