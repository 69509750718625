import {Component, Input} from '@angular/core';
import {ThemePalette} from "@angular/material/core";
import {RmaStatus, RmaStatusType} from "../../api/model/rma";
import moment from "moment/moment";

@Component({
  selector: 'app-rma-status',
  templateUrl: './rma-status.component.html',
  styleUrls: ['./rma-status.component.sass']
})
export class RmaStatusComponent {
  @Input() status: RmaStatus | undefined;
  @Input() condense: Boolean = false;

  iconColor(): ThemePalette {
    return this.status?.type == RmaStatusType.ON_CLIENT_PREMISES ? 'primary' : undefined;
  }

  isYellow(): boolean {
    return this.status?.type != RmaStatusType.ON_CLIENT_PREMISES;
  }

  tooltip(): string {
    const date = this.status?.date
    if (date) {
      return moment(date).format("YYYY-MM-DD");
    } else {
      return "";
    }
  }
}
