import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {Rma} from "./model/rma";

@Injectable()
export class RmaService {
  constructor(private http: HttpClient) {
  }

  list(filter?: string): Observable<Rma[]> {
    let params = new HttpParams();
    if (filter) params = params.append("filter", filter);
    return this.http.get<Rma[]>(environment.apiUrl + "/rma", {params})
  }

  getByNum(rmaNumber: string): Observable<Rma> {
    return this.http.get<Rma>(environment.apiUrl + "/rma/byNum/" + rmaNumber)
  }

  get(id: string): Observable<Rma> {
    return this.http.get<Rma>(environment.apiUrl + "/rma/" + id)
  }

  create(request: Rma): Observable<Rma> {
    return this.http.post<Rma>(environment.apiUrl + "/rma", request)
  }

  delete(id: string): Observable<any> {
    return this.http.delete(environment.apiUrl + "/rma/" + id)
  }

  update(id: string, request: Rma): Observable<Rma> {
    return this.http.put<Rma>(environment.apiUrl + "/rma/" + id, request)
  }
}
