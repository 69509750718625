<h1 class="mat-h1">Welcome!</h1>
<p>Enter your credentials to proceed.</p>

<form class="form" [formGroup]="loginForm" (ngSubmit)="onSubmit()">

  <mat-form-field class="wide">
    <mat-label>Enter your email</mat-label>
    <input type="email" matInput formControlName="email" placeholder="Ex. tomas@sonus.lt">
    <mat-error *ngIf="loginForm.controls.email.hasError('email') && !loginForm.controls.email.hasError('required')">
      Please enter a valid email address
    </mat-error>
    <mat-error *ngIf="loginForm.controls.email.hasError('required')">
      Email is <strong>required</strong>
    </mat-error>
  </mat-form-field>
  <mat-form-field class="wide" appearance="fill">
    <mat-label>Enter your password</mat-label>
    <input matInput formControlName="password" [type]="passwordHidden ? 'password' : 'text'">
    <button mat-icon-button matSuffix (click)="passwordHidden = !passwordHidden" [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="passwordHidden">
      <mat-icon>{{passwordHidden ? 'visibility_off' : 'visibility'}}</mat-icon>
    </button>
  </mat-form-field>
  <button type="submit" mat-raised-button color="primary" [disabled]="!loginForm.valid">Login</button>
  <mat-error *ngIf="loginForm.hasError('credentials')">
    Invalid credentials provided
  </mat-error>
</form>
